import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import useSharedLink from "../../../scenes/SharedLink";
import axiosInstance from "../../../components/AxiosInstance";
import GoalComponent from "../../../components/GoalComponent";
import { useSnackbar } from "../../../components/SnackBarProvider";
import { themeStyles, buttonStyle } from "../../../constants";

const GoalModal = ({
  open,
  handleClose,
  goalvalues,
  removeGoal,
  type,
  updateGoal,
}) => {
  console.log(goalvalues, "modal", type);
  const sharedLink = useSharedLink();
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [goals, setGoals] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState({
    goalCriteriaName: "All",
  });
  const [currentValues, setCurrentValues] = useState(goalvalues);
  console.log(currentValues, "modal current values");
  const { openSnackbar } = useSnackbar();
  const extractCriteria = (node, result = []) => {
    if (node.goalBankAreaStatus === "Active") {
      result.push({
        id: node.id,
        goalCriteriaName: node.goalBankAreaName,
      });
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => extractCriteria(child, result));
    }
    return result;
  };
  useEffect(() => {
    axiosInstance
      .post(sharedLink + "/viewgoalbankarea")
      .then((response) => {
        const criteriaList = extractCriteria(response.data?.data);
        console.log(
          "criteria list",
          criteriaList,
          "response",
          response.data?.data
        );
        setCriteria([{ goalCriteriaName: "All" }, ...criteriaList]);
      })
      .catch((error) => console.log(error));
  }, [sharedLink]);

  useEffect(() => {
    console.log(selectedCriteria);
    const fetchGoals = () => {
      const endpoint =
        selectedCriteria.goalCriteriaName === "All"
          ? "/viewallgoals"
          : `/viewgoals`;
      axiosInstance
        .post(sharedLink + endpoint, { goalareaId: selectedCriteria?.id })
        .then((response) => {
          const activeGoals = response.data.filter(
            (goal) => goal.goalStatus === "Active"
          );
          setGoals(activeGoals);
        })
        .catch((error) => console.log(error));
    };
    fetchGoals();
  }, [selectedCriteria]);

  useEffect(() => {
    if (goalvalues) {
      setCurrentValues(goalvalues);
      setSelectedGoal(goalvalues);
      setInputValue(goalvalues.goalName || null);
    }
  }, [goalvalues]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  const handleCriteriaChange = (event, newValue) => {
    setSelectedCriteria(newValue);
  };
  const handleAutocompleteChange = (event, newValue) => {
    const { id, ...rest } = newValue || {}; // Destructure to remove the id field
    const payload = { iid: goalvalues.iid, ...rest };

    if (goalvalues.id) {
      updateGoal({ ...payload, id: goalvalues.id });
    } else {
      updateGoal(payload);
    }

    setSelectedGoal(newValue);
    setCurrentValues(payload);
  };

  const validateGoal = (payload) => {
    console.log(payload, "goal is ther");
    const goal = {
      ...payload,
      goalAreaName: payload.goalAreaName
        ? payload.goalAreaName
        : payload?.goaltype?.goalAreaName,
      goalAreaId: payload.goalAreaId
        ? payload.goalAreaId
        : payload?.goaltype?.goalAreaId,
    };
    console.log(goal, "validating");
    delete goal["goaltype"];

    let requiredFields = [
      "goalName",
      "goalDescription",
      "goalStatus",
      "goalAreaId",
      "goalAreaName",
      "masteryCriteria",
      "masteryDirection",
      "masteryDuration",
    ]; // Common fields for all program types
    if (type && type === "book") requiredFields.push("masteryTargetDate");

    // Adding specific fields based on programType
    switch (goal.programType) {
      case "Task Analysis":
        requiredFields.push("trialList", "noOfAttempts"); // For 'Task Analysis', 'trialList', 'noOfAttempts', and 'prompt' are also required
        break;
      case "Duration":
        requiredFields.push("noOfAttempts");
        break;
      case "Percentage":
        requiredFields.push("prompt", "noOfAttempts"); // For 'Duration' and 'Percentage', ensure 'prompt' and 'noOfAttempts' are included
        break;
      default:
        // You can specify any additional cases for other program types if needed
        break;
    }

    // Log all field checks for debugging
    requiredFields.forEach((field) => {
      console.log(field, " ", goal[field]);
    });

    // Validate all required fields
    for (const field of requiredFields) {
      if (
        goal[field] === undefined ||
        goal[field] == null ||
        goal[field] === ""
      ) {
        console.log(`Missing or empty required field: ${field}`);
        return false; // Return false if any required field is empty or undefined
      }
    }

    return true; // All required fields are filled
  };

  const handleDone = () => {
    if (validateGoal(currentValues)) {
      updateGoal({ ...currentValues });
      handleClose();
    } else {
      openSnackbar("Please Fill all the fields properly", "error");
      console.log("Please fill all required fields.");
    }
  };

  const handleGoalUpdate = (newValue) => {
    const payload = { iid: goalvalues.iid, ...newValue };
    if (goalvalues.id) {
      setCurrentValues({ ...payload, id: goalvalues.id });
    } else {
      setCurrentValues(payload);
    }
  };

  return (
    <Dialog open={open} onClose={handleDone} fullWidth maxWidth="sm">
      <DialogTitle>{goalvalues?.id ? "Edit Goal" : "Add Goal"}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))">
            <Autocomplete
              value={selectedCriteria}
              disabled={goalvalues?.id}
              onChange={handleCriteriaChange}
              options={criteria}
              getOptionLabel={(option) => option.goalCriteriaName || "All"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Goal Criteria"
                  variant="outlined"
                />
              )}
              sx={{ gridColumn: "span 2", ...themeStyles.textField }}
            />
            <Autocomplete
              value={selectedGoal || null}
              onChange={handleAutocompleteChange}
              // inputValue={inputValue}
              disabled={goalvalues?.id}
              onInputChange={handleInputChange}
              options={goals}
              getOptionLabel={(option) =>
                option && option.goalName && option.goalAreaName
                  ? `${option.goalName} (${option.goalAreaName})`
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Enter Goal"
                  variant="outlined"
                />
              )}
              sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              freeSolo
            />
          </Box>
          <GoalComponent
            key={selectedGoal?.id || "new"} // Use a unique key to ensure re-render
            goalid={selectedGoal?.id}
            handleGoalUpdate={handleGoalUpdate}
            goalvalues={currentValues}
            type={type}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={() => {
            if (validateGoal(currentValues)) {
              handleClose();
            } else {
              openSnackbar("Please fill the values properly", "error");
            }
          }}
          sx={{ ...buttonStyle }}>
          Close
        </Button> */}
        <Button onClick={handleDone} sx={{ ...buttonStyle }}>
          Save
        </Button>
        <Button
          onClick={() => {
            removeGoal(currentValues?.iid);
            handleClose();
          }}
          sx={{ ...buttonStyle }}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoalModal;
