import { Box, Button, TextField, Autocomplete, Alert } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  themeStyles,
  buttonStyle,
  GENDERS,
  STATUS,
  STATES,
} from "../../constants";
import dayjs from "dayjs";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import useSharedLink from "../SharedLink";
import { useSnackbar } from "../../components/SnackBarProvider";
import LoadingIndicator from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { cleanText } from "../../utils/textUtils";
import {
  fetchRoles,
  fetchStaff,
  fetchSupervisorByRole,
  addOrUpdateStaff,
} from "../../Redux/ActionCreators/StaffActionCreator";

const StaffForm = ({ mode }) => {
  const sharedLink = useSharedLink();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [facilities, setFacilities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [loading, setLoading] = useState(mode === "update");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("Id");
  const dispatch = useDispatch();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const ROLES = useSelector((state) => state.roles);
  const SUPERVISORS = useSelector((state) => state.staffs.supervisors);
  console.log(ROLES, "roles");
  console.log(SUPERVISORS, "staffs");

  useEffect(() => {
    console.log(mode);
    setInitialValues(defaultInitialValues);
    const fetchData = async () => {
      try {
        const facilitiesRes = await axiosInstance.post(
          `${sharedLink}/viewallfacility`,
          { withCredentials: true }
        );
        const formattedFacilities = facilitiesRes.data.map(
          (val) => `${val.facilityName} (${val.id})`
        );
        setFacilities(formattedFacilities);

        // const supervisorsRes = await axios.post(`${sharedLink}/viewallstaff`, {
        //   role: "Supervisor",
        // });
        // const formattedSupervisors = supervisorsRes.data.map(
        //   (val) => `${val.firstName} ${val.lastName} (${val.id})`
        // );
        // setSupervisors(formattedSupervisors);

        if (mode === "update" && id) {
          const staffRes = await axiosInstance.post(
            `${sharedLink}/viewstaff`,
            { id },
            { withCredentials: true }
          );
          const staffData = staffRes.data[0];

          dispatch(fetchSupervisorByRole(staffData?.role));

          console.log(staffData);
          const supervisorArray = staffData.supervisor.map((id, index) => {
            const matchedSupervisor = (SUPERVISORS || []).find(
              (sup) => parseInt(sup.id) === parseInt(id)
            );
            return {
              id: id,
              name: staffData.supervisorName[index],
              role: matchedSupervisor?.role || "Unknown Role",
            };
          });
          const initialData = {
            ...defaultInitialValues,
            firstname: staffData.firstName,
            lastname: staffData.lastName,
            email: staffData.email,
            streetaddress: staffData.streetAddress,
            city: staffData.city,
            state: staffData.state,
            country: staffData.country,
            zipcode: staffData.zipCode,
            title: staffData.title,
            gender: staffData.gender,
            role: { role: staffData.role },
            supervisor: supervisorArray,
            phonenumber: staffData.phoneNumber,
            npi: staffData.npi,
            licensenumber: staffData.licenseNumber,
            licenseexpirationdate: staffData.licenseExpirationDate
              ? dayjs(staffData.licenseExpirationDate)
              : null,
            dateofbirth: staffData.dateOfBirth
              ? dayjs(staffData.dateOfBirth)
              : null,
            status: staffData.status,
            facilityid: `${staffData.facilityName} (${staffData.facilityId})`,
          };
          console.log(initialData);
          setInitialValues(initialData);
        } else {
        }
        setLoading(false);
      } catch (error) {
        console.error("Error during the request:", error);
        openSnackbar("Error fetching data", "error");
        setLoading(false);
      }
    };

    fetchData();
  }, [id, dispatch]);
  useEffect(() => {
    if (SUPERVISORS.length && initialValues.supervisor?.length) {
      // Map supervisor roles after SUPERVISORS are available
      const updatedSupervisors = initialValues.supervisor.map((sup) => {
        const matchedSupervisor = SUPERVISORS.find(
          (item) => parseInt(item.id) === parseInt(sup.id)
        );
        return {
          ...sup,
          role: matchedSupervisor?.role || "Unknown Role",
        };
      });

      setInitialValues((prev) => ({
        ...prev,
        supervisor: updatedSupervisors,
      }));
    }
  }, [SUPERVISORS, initialValues.supervisor]);
  const handleReset = (resetForm) => {
    resetForm();
  };

  const handleFormSubmit = async (
    values,
    { resetForm, setErrors, validateForm }
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      openSnackbar("Please fill out all required fields", "error");
      return;
    }

    setIsSubmitting(true);
    openSnackbar("Processing...", "info");

    const formattedDateOfBirth = values.dateofbirth
      ? dayjs(values.dateofbirth).format("YYYY-MM-DD")
      : null;
    const formattedLicenseExpiry = values.licenseexpirationdate
      ? dayjs(values.licenseexpirationdate).format("YYYY-MM-DD")
      : null;
    const supervisorIds = Array.isArray(values.supervisor)
      ? values.supervisor.map((sup) => sup.id).join(",") // Map to IDs and join with commas
      : values.supervisor?.id || "";
    const payload = {
      ...values,
      dateofbirth: formattedDateOfBirth,
      licenseexpirationdate: formattedLicenseExpiry,
      supervisor: supervisorIds,
      facilityid: values.facilityid?.split("(")[1].split(")")[0],
      enteredby: localStorage.getItem("username"),
      lastupdatedby: localStorage.getItem("username"),
      role: values.role.role,
    };

    try {
      console.log("payload", payload);
      if (mode == "update") payload.id = id;
      const error = await dispatch(addOrUpdateStaff(payload, mode));
      if (!error) {
        setSubmissionSuccess(true);
        openSnackbar(
          `Staff ${mode === "add" ? "added" : "updated"} successfully`,
          "success"
        );
        if (mode === "add") resetForm(); // Reset form only on successful add
      } else {
        openSnackbar(`Error: ${error}`, "error");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      openSnackbar(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleInputChange = (event, setFieldValue) => {
    const cleanValue = cleanText(event.target.value); // Allows specific special characters
    setFieldValue(event.target.name, cleanValue); // Update Formik field with cleaned value
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="staff-background">
      <Box m="20px">
        <Header
          title={`${mode === "add" ? "Add" : "Update"} Staff`}
          subtitle=""
          className="headerStyle"
        />
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          enableReinitialize
          validateOnBlur={true}
          validateOnChange={true}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
            validateForm, // Include this
            setErrors,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}>
                <Autocomplete
                  fullWidth
                  options={facilities}
                  autoHighlight
                  value={values.facilityid}
                  onChange={(event, newValue) => {
                    setFieldValue("facilityid", newValue);
                  }}
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility Name"
                      variant="filled"
                      required
                      disabled={values.status == "Inactive"}
                      error={touched.facilityid && Boolean(errors.facilityid)}
                      helperText={touched.facilityid && errors.facilityid}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Facility Name",
                      }}
                    />
                  )}
                  sx={{ ...themeStyles.textField, gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="User Name (Email)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  disabled={values.status == "Inactive"}
                  name="email"
                  required
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Password"
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.password}
                  name="password"
                  required={mode == "add"}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.firstname}
                  disabled={values.status == "Inactive"}
                  name="firstname"
                  required
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.lastname}
                  disabled={values.status == "Inactive"}
                  name="lastname"
                  required
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Contact Number"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.phonenumber}
                  disabled={values.status == "Inactive"}
                  name="phonenumber"
                  required
                  inputProps={{ maxLength: 10 }}
                  error={touched.phonenumber && Boolean(errors.phonenumber)}
                  helperText={touched.phonenumber && errors.phonenumber}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <DatePicker
                  fullWidth
                  variant="filled"
                  label="Date of Birth"
                  onBlur={handleBlur}
                  required
                  disabled={values.status == "Inactive"}
                  onChange={(value) => setFieldValue("dateofbirth", value)}
                  value={values.dateofbirth || null}
                  name="dateofbirth"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      disabled: values.status == "Inactive",
                      error: touched.dateofbirth && Boolean(errors.dateofbirth),
                      helperText: touched.dateofbirth && errors.dateofbirth,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                  disableFuture
                />

                <Autocomplete
                  fullWidth
                  options={GENDERS}
                  autoHighlight
                  value={values.gender}
                  onChange={(event, newValue) => {
                    setFieldValue("gender", newValue);
                  }}
                  onBlur={handleBlur}
                  disabled={values.status == "Inactive"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gender"
                      variant="filled"
                      required
                      disabled={values.status == "Inactive"}
                      error={touched.gender && Boolean(errors.gender)}
                      helperText={touched.gender && errors.gender}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Gender",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Country"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.country}
                  disabled={values.status == "Inactive"}
                  name="country"
                  required
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  sx={{
                    gridColumn: "span 2",
                    ...themeStyles.textField,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Street Address"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.streetaddress}
                  disabled={values.status == "Inactive"}
                  name="streetaddress"
                  required
                  error={touched.streetaddress && Boolean(errors.streetaddress)}
                  helperText={touched.streetaddress && errors.streetaddress}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={STATES}
                  autoHighlight
                  value={values.state}
                  onChange={(event, newValue) => {
                    setFieldValue("state", newValue);
                  }}
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="filled"
                      required
                      disabled={values.status == "Inactive"}
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select state",
                      }}
                    />
                  )}
                  sx={{
                    ...themeStyles.textField,
                    gridColumn: "span 2",
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="City"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.city}
                  disabled={values.status == "Inactive"}
                  name="city"
                  required
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Zip code"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.zipcode}
                  disabled={values.status == "Inactive"}
                  name="zipcode"
                  required
                  error={touched.zipcode && Boolean(errors.zipcode)}
                  helperText={touched.zipcode && errors.zipcode}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={ROLES || []}
                  disabled={values.status == "Inactive"}
                  autoHighlight
                  getOptionLabel={(option) => option.role}
                  value={
                    (ROLES || []).find(
                      (role) => role.role == values.role.role
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue.role) {
                      dispatch(fetchSupervisorByRole(newValue?.role));
                      setFieldValue("supervisor", null);
                    }
                    // console.log(matchedRole, " ", newValue.role);
                    setFieldValue("role", newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role"
                      variant="filled"
                      required
                      error={touched.role && Boolean(errors.role)}
                      helperText={touched.role && errors.role}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select role",
                      }}
                    />
                  )}
                  sx={{
                    ...themeStyles.textField,
                    gridColumn: "span 2",
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="National Provider Identification Number"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.npi}
                  disabled={values.status == "Inactive"}
                  name="npi"
                  error={touched.npi && Boolean(errors.npi)}
                  helperText={touched.npi && errors.npi}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Individual License Number"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.licensenumber}
                  disabled={values.status == "Inactive"}
                  name="licensenumber"
                  error={touched.licensenumber && Boolean(errors.licensenumber)}
                  helperText={touched.licensenumber && errors.licensenumber}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <DatePicker
                  fullWidth
                  variant="filled"
                  label="License expiration date"
                  disabled={
                    !values.licensenumber || values.status == "Inactive"
                  }
                  value={
                    values.licenseexpirationdate
                    // ? dayjs(values.licenseexpirationdate)
                    // : null
                  }
                  onChange={(newValue) =>
                    setFieldValue("licenseexpirationdate", newValue)
                  }
                  disablePast
                  name="licenseexpirationdate"
                  // error={
                  //   touched.licenseexpirationdate &&
                  //   Boolean(errors.licenseexpirationdate)
                  // }
                  // helperText={
                  //   touched.licenseexpirationdate &&
                  //   errors.licenseexpirationdate
                  // }
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <Autocomplete
                  fullWidth
                  options={STATUS}
                  autoHighlight
                  value={values.status}
                  onChange={(event, newValue) => {
                    setFieldValue("status", newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="filled"
                      required
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select status",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Title"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.title}
                  disabled={values.status == "Inactive"}
                  name="title"
                  required
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  multiple // Enables multiple selection
                  options={SUPERVISORS || []}
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Match by ID
                  getOptionLabel={(option) => `${option.name} (${option.role})`}
                  value={values.supervisor || []} // Updated to handle an array of selected supervisors
                  disabled={values.status == "Inactive"}
                  onChange={(event, newValue) => {
                    // Update the field value with an array of selected supervisors
                    setFieldValue("supervisor", newValue);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supervisor"
                      variant="filled"
                      required
                      error={touched.supervisor && Boolean(errors.supervisor)}
                      helperText={touched.supervisor && errors.supervisor}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Supervisor",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                {mode == "update" ? (
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ ...buttonStyle }}>
                    Go Back
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                    }}
                    sx={{ ...buttonStyle }}>
                    Clear
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  // onClick={async () => {
                  //   const errors = await validateForm(); // Validate form fields
                  //   if (Object.keys(errors).length > 0) {
                  //     setErrors(errors); // Set form errors if validation fails
                  //     openSnackbar(
                  //       "Please fill out all required fields",
                  //       "error"
                  //     );
                  //     return;
                  //   }

                  //   handleFormSubmit(values, {
                  //     resetForm,
                  //     setErrors,
                  //     validateForm,
                  //   }); // Call your form submit logic
                  // }}
                  sx={{ ...buttonStyle }}>
                  {mode === "add" ? "Add Staff" : "Update Staff"}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => navigate("/managestaff")}
                  sx={{ ...buttonStyle }}>
                  See in Manage Staff
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  facilityid: yup.string().required("Facility is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  // password: yup.string().required("Password is required"),
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required"),
  phonenumber: yup
    .string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required"),
  dateofbirth: yup.date().required("Date of birth is required"),
  gender: yup.string().required("Gender is required"),
  country: yup.string().required("Country is required"),
  streetaddress: yup.string().required("Street address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipcode: yup.string().required("Zip code is required"),
  title: yup.string().required("Title is required"),
  role: yup.object().required("Role is required"),
  supervisor: yup.array().required("Supervisor is required"),
  status: yup.string().required("Status is required"),
});

const defaultInitialValues = {
  facilityid: "",
  firstname: "",
  lastname: "",
  email: "",
  dateofbirth: null,
  streetaddress: "",
  city: "",
  state: "",
  country: "United States",
  zipcode: "",
  title: "",
  gender: "",
  role: "",
  supervisor: null,
  phonenumber: "",
  npi: "",
  licensenumber: "",
  licenseexpirationdate: null,
  password: "",
  status: "Active",
};

export default StaffForm;
