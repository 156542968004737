import dayjs from "dayjs";
// export const API_BASE_URL = "https://lucidsoftgen.azurewebsites.net"; //dev API server

export const API_BASE_URL = "https://lucidnotes.azurewebsites.net"; // Client Azure API server
export const ROOT_FOLDER = "QualityABA";
export const SYSTEM_ADMIN = "admin";
export const CALENDAR = "calendar";
export const CLINICAL_ASSISTANT = "clinicalAssistant";
export const LEAD_RBT = "Lead RBT";
export const RB_BT = "BT/RBT";
export const CLINICAL_DIRECTOR = "Clinical Director";
export const EXECUTIVE_OFFICER = "Executive Director";
export const DASHBOARD = "dashboard";
export const ADMIN_PAGE = "admin";
export const CONSENT_PARTY = "Consent Party";
export const CLIENT = "Client";
export const DATA_COLLECTION_PAGE = "dataCollection";
export const DATA_ENTRY_PAGE = "dataEntry";
export const STAFF_PAGE = "staff";
export const FACILITY_PAGE = "facility";
export const CLIENT_PAGE = "client";
export const MANAGE_GUARDIAN_PAGE = "manageguardian";
export const SESSION_NOTE_PAGE = "sessionNote";
export const SESSION_NOTE_97153 = "sessionNote97153";
export const SESSION_NOTE_97151 = "sessionNote97151";
export const SESSION_NOTE_97155 = "sessionNote97155";
export const SESSION_NOTE_97156 = "sessionNote97156";
export const SESSION_NOTE_H0032 = "sessionNoteH0032";
export const MANAGE_CONSENT_PAGE = "manageConsent";
export const SCHEDULER_PAGE = "scheduler";
export const PROGRAM_BOOK = "programBook";
export const PROGRAM_BOOK_TEMPLATE = "programBookTemplate";
export const PERFORMANCE_GRAPH_PAGE = "clientPerformanceGraph";
export const UPDATE = "U";
export const DELETE = "D";
export const CREATE = "C";
export const READ = "R";
export const ALL_SCREENS = "All Screens";
export const GOAL = "goal";
export const DATA_COLLECTION_TYPE = "dataCollectionType";
export const RELATIONSHIPOPTIONS = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Friend",
  "Other",
];
export const TYPES = ["Percentage", "Frequency", "Duration", "Task Analysis"];
export const PROMPTS = [
  "Physical",
  "Pictorial",
  "Gestural",
  "Modeling",
  "Textual",
  "Verbal",
  "Positional",
  "Independent",
];
export const DIRECTIONS = ["Increasing", "Reducing"];
export const MASTERY_TYPES = ["Automatic", "Manual"];
export const EVENT_STATUS = ["Pending", "Completed"];
export const GENDERS = ["Female", "Male", "Prefer not to say"];
export const ROLES = [
  "Staff",
  "Supervisor",
  "Biller",
  "Clinical Assitant",
  "Other",
];

export const DIAGNOSISCODES = ["F84.0", "Other"];

export const STATUS = ["Active", "Inactive"];
export const MARITALSTATUS = [
  "Single",
  "Married",
  "Divorced",
  "Widowed",
  "Separated",
  "Other",
];
export const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const DATA_COLLECTION_TYPES = [
  "Baseline",
  "Practicing",
  "Mastery",
  "Maintenance",
];

// styles.js
export const textFieldStyles = (value) => ({
  "& .MuiOutlinedInput-root": {
    ...(value === "100" && {
      "& fieldset": {
        borderColor: "green",
      },
      "&:hover fieldset": {
        borderColor: "green", // Maintain green border on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "green", // Maintain green border when focused
      },
    }),
  },
});

export const buttonStyle = {
  minWidth: "140px", // Sets a minimum width for the buttons
  height: "40px", // Sets the height for the buttons
  backgroundColor: "#3A57E8", // Sets the color for the buttons
  marginRight: "20px",
  marginBottom: "20px", // Adds margin at the bottom

  color: "white",
  borderRadius: "20px", // Makes the corners rounded
  "&:hover": {
    backgroundColor: "#115293",
  },
};

export const themeStyles = {
  textField: {
    "& .MuiInputBase-root": {
      color: "black",
      backgroundColor: "white", // Ensure the background color is set to white or any other color you need
      boxShadow: "0px 3px 6px #00000029", // Customize the drop shadow as needed
      borderRadius: "5px",

      "& input": {
        color: "black",
      },
      // Removed border styling, as we're now using a drop shadow
    },
    "& .MuiInputLabel-root": {
      color: "black",
      // Style adjustments for when the input is focused or has a value
      "&.Mui-focused": {
        color: "black",
      },
    },
    // Remove the underline styling as we're focusing on drop shadow
    "& .MuiFilledInput-underline:before": {
      display: "none",
    },
    "& .MuiFilledInput-underline:after": {
      display: "none",
    },
    // Adjust the label to move properly when the input is focused
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      backgroundColor: "white",
      padding: "0 4px",
    },
    // Apply the drop shadow to the notched outline as well for consistency
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide the default border
    },
  },
  // constants.js

  autocompleteStyles: {
    "& .MuiInputBase-root": {
      color: "black",
      backgroundColor: "transparent",
      borderColor: "black",
      borderWidth: "3px",
      borderStyle: "solid",
      borderRadius: "10px",
      "& input": {
        color: "black",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "black",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "black",
    },
    gridColumn: "span 2",
  },
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Set the width as required
  maxWidth: "80vw",
  maxHeight: "80vh",
  overflowY: "auto", // In case the content is too big
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const collectionTypeColors = {
  baseline: "#FF0000", // Red
  mastery: "#00FF00", // Green
  practicing: "#0000FF", // Blue
  maintenance: "#FFFF00", // Yellow
  default: "#000000", // Black for any other or undefined collectionType
};
export function formatTimestamp(timestamp) {
  // Check if the timestamp seems to be in seconds and adjust if necessary
  return dayjs(timestamp < 10000000000 ? timestamp * 1000 : timestamp).format(
    "YYYY-MM-DD"
  );
}
