import React from "react";
import { Col } from "react-bootstrap";
import BreadCrum from "../BreadCrum.js";
import SubNavRightComponent from "../SubNavRightComponent/index.js";

const SubNav = ({ currentFolder }) => {
  console.log("currentFolder", currentFolder);
  return (
    <Col
      md={12}
      className={"d-flex align-items-center px-5 pt-3 justify-content-between"}>
      {currentFolder && currentFolder !== "root folder" ? (
        <>
          <BreadCrum currentFolder={currentFolder} />

          <div className="ml-auto col-md-5 d-flex justify-content-end">
            <SubNavRightComponent currentFolder={currentFolder} />
          </div>
        </>
      ) : (
        <>
          <p>Root</p>
          <div className="ml-auto col-md-5 d-flex justify-content-end">
            {/* <UploadFile currentFolder={currentFolder} />
            &nbsp;
            <CreateFile currentFolder={currentFolder} /> */}
            &nbsp;
            <SubNavRightComponent currentFolder={currentFolder} />
          </div>
        </>
      )}
    </Col>
  );
};

export default SubNav;
