import React, { useState } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Delete as TrashIcon } from "@mui/icons-material";
import { themeStyles } from "../../../constants";
import { cleanText } from "../../../utils/textUtils";

const AddPriorAuthorization = ({
  formData,
  setFormData,
  additionalFields,
  setAdditionalFields,
  handleSavePriorAuthorization,
  priorAuthorizationDates,
  setPriorAuthorizationDates,
  rowFieldsEditable,
  fieldsEditable,
  setRowFieldsEditable,
}) => {
  const [
    priorAuthorizationStopMonitoring,
    setPriorAuthorizationStopMonitoring,
  ] = useState(false);

  const handleInputChange = (field, index) => (event, newValue) => {
    // Check if event.target.value exists and is a string; otherwise, use an empty string
    const inputValue =
      typeof event?.target?.value === "string" ? event.target.value : "";
    const cleanValue = cleanText(inputValue);

    const newFields = [...additionalFields];
    // If newValue exists and is a string, use it; otherwise, use cleanValue
    newFields[index][field] =
      typeof newValue === "string" ? cleanText(newValue) : cleanValue;

    setAdditionalFields(newFields);
  };

  const handleAddField = () => {
    setAdditionalFields([
      ...additionalFields,
      { id: additionalFields.length + 1, procedure: null, unit: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    const newFields = additionalFields.filter((_, i) => i !== index);
    setAdditionalFields(newFields);
  };

  const handlePriorAuthorizationCheckboxChange = (event) => {
    setPriorAuthorizationStopMonitoring(event.target.checked);
  };

  const handleCheckboxChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      stopMonitoring: event.target.checked,
    }));
  };

  const validateUnits = (event, index) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      handleInputChange("unit", index)(event);
    }
  };

  const procedureOptions = [
    { label: "97151" },
    { label: "97152" },
    { label: "97153" },
    { label: "97155" },
    { label: "97156" },
    { label: "97158" },
    { label: "H0032" },
  ];

  // Add this function to handle date conversion
  const setMidnight = (date) => dayjs(date);

  // Update the handleStartDateChange function to use setMidnight
  const handleStartDateChange = (newValue) => {
    setPriorAuthorizationDates((prevDates) => ({
      ...prevDates,
      startDate: setMidnight(newValue),
      // Set endDate to 6 months after the startDate
      endDate: setMidnight(dayjs(newValue).add(6, "month")),
    }));
  };

  // Update the handleEndDateChange function to use setMidnight
  const handleEndDateChange = (newValue) => {
    setPriorAuthorizationDates((prevDates) => ({
      ...prevDates,
      endDate: setMidnight(newValue),
    }));
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          mt: 2,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={formData.priorAuthorizationStopMonitoring}
              // onChange={handlePriorAuthorizationCheckboxChange}
              disabled={!rowFieldsEditable}
              onChange={(event, newValue) =>
                setFormData((prevData) => ({
                  ...prevData,
                  priorAuthorizationStopMonitoring: newValue,
                }))
              }
            />
          }
          label={
            <span style={{ fontWeight: "bold", fontSize: 18 }}>
              Stop Monitoring
            </span>
          }
          className="animated-input"
        />
      </Box>

      <Autocomplete
        fullWidth
        options={[
          { label: "Assessment" },
          { label: "Initial Service Request" },
          { label: "Concurrent Service Request" },
          { label: "Social Skill Service Request" },
          { label: "Other" },
          { label: "NA" },
        ]}
        getOptionLabel={(option) => option.label}
        className="animated-input"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={
              <span>
                Authorization Type<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            margin="normal"
          />
        )}
        value={formData.authorizationType}
        onChange={(event, newValue) =>
          setFormData((prevData) => ({
            ...prevData,
            authorizationType: newValue,
          }))
        }
        sx={{ flex: "1 1 48%", ...themeStyles.textField }}
        disabled={!rowFieldsEditable}
      />

      <TextField
        label={
          <span>
            Authorization Number<span style={{ color: "red" }}>*</span>
          </span>
        }
        variant="outlined"
        value={formData.authorizationNumber}
        className="animated-input"
        onChange={(event) =>
          setFormData((prevData) => ({
            ...prevData,
            authorizationNumber: cleanText(event.target.value),
          }))
        }
        fullWidth
        margin="normal"
        sx={{ flex: "1 1 48%", ...themeStyles.textField }}
        disabled={!rowFieldsEditable}
      />

      {/* Use handleStartDateChange and handleEndDateChange */}
      <DatePicker
        label={
          <span>
            Start Date<span style={{ color: "red" }}>*</span>
          </span>
        }
        value={priorAuthorizationDates.startDate}
        onChange={handleStartDateChange}
        className="animated-input"
        renderInput={(params) => (
          <TextField {...params} fullWidth margin="normal" />
        )}
        sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
        disabled={!rowFieldsEditable}
      />

      <DatePicker
        label={
          <span>
            End Date<span style={{ color: "red" }}>*</span>
          </span>
        }
        value={priorAuthorizationDates.endDate}
        onChange={handleEndDateChange}
        minDate={dayjs(priorAuthorizationDates.startDate)} // Minimum selectable date is the start date
        className="animated-input"
        renderInput={(params) => (
          <TextField {...params} fullWidth margin="normal" />
        )}
        sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
        disabled={!rowFieldsEditable}
      />

      <Autocomplete
        fullWidth
        options={[{ label: "Active" }, { label: "Inactive" }]}
        getOptionLabel={(option) => option?.label || ""}
        className="animated-input"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={
              <span>
                Status<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            margin="normal"
          />
        )}
        value={formData.authorizationActive}
        onChange={(event, newValue) => {
          if (newValue) {
            setFormData((prevData) => ({
              ...prevData,
              authorizationActive: newValue,
            }));
            setRowFieldsEditable(newValue.label === "Active");
          } else {
            setFormData((prevData) => ({
              ...prevData,
              authorizationActive: null,
            }));
          }
        }}
        sx={{ flex: "1 1 48%", ...themeStyles.textField }}
      />

      <Autocomplete
        fullWidth
        options={[
          { label: "Approved Initial Assessment" },
          { label: "Approved Initial Service Request" },
          { label: "Submitted Initial Assessment Request" },
          { label: "Submitted Initial Service Request" },
          { label: "Received Initial Service Request Documents" },
          { label: "Received Concurrent Service Request Documents" },
          { label: "Rejected Initial Service Request" },
          { label: "Rejected Concurrent Service Request" },
          { label: "Rejected Concurrent Service Request" },
          { label: "Not Required Authorization for Initial Assesment" },
          { label: "Not Required Authorization for Service Request" },
          { label: "Not Started Authorization" },
          { label: "Approved Concurrent Service Request" },
          { label: "Expired" },
          { label: "Single Case Agreement" },
        ]}
        getOptionLabel={(option) => option.label}
        className="animated-input"
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={
              <span>
                Current Authorization State
                <span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            margin="normal"
          />
        )}
        value={formData.currentAuthorizationState}
        onChange={(event, newValue) =>
          setFormData((prevData) => ({
            ...prevData,
            currentAuthorizationState: newValue,
          }))
        }
        sx={{ flex: "1 1 48%", ...themeStyles.textField }}
        disabled={!rowFieldsEditable}
      />

      {additionalFields.map((field, index) => (
        <Box
          key={field.id}
          sx={{ display: "flex", gap: "16px", width: "100%" }}
        >
          <Autocomplete
            fullWidth
            options={procedureOptions} // List of predefined procedure codes
            getOptionLabel={(option) =>
              // Display the label of the option if it's an object, otherwise return an empty string
              typeof option === "string" ? option : option.label || ""
            }
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Procedure Code<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            autoHighlight // Enable highlighting of matched options
            value={field.procedure || null} // Ensure value is always an object or null
            onChange={(event, newValue) => {
              // Handle selection from the dropdown only
              const selectedValue = newValue
                ? { label: cleanText(newValue.label) }
                : null;

              // Create a copy of additionalFields and update the procedure field
              const newFields = [...additionalFields];
              newFields[index].procedure = selectedValue;
              // Update the state with the modified additionalFields
              setAdditionalFields(newFields);
            }}
            filterOptions={(options, { inputValue }) => {
              // Filter options to match input exactly, or show "No options" if no match
              const cleanedInput = cleanText(inputValue);
              return options.filter((option) =>
                option.label.toLowerCase().includes(cleanedInput.toLowerCase())
              );
            }}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
            disabled={!rowFieldsEditable} // Disable input if not editable
          />

          <TextField
            label={
              <span>
                Units<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            value={field.unit}
            className="animated-input"
            onChange={(event) => validateUnits(event, index)}
            fullWidth
            margin="normal"
            type="Number"
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
            disabled={!rowFieldsEditable}
          />

          <IconButton
            onClick={() => handleRemoveField(index)}
            className="animated-input"
            sx={{
              mt: 2,
              fontSize: 32,
              color: "grey",
            }}
            disabled={!rowFieldsEditable}
          >
            <TrashIcon fontSize="inherit" />
          </IconButton>
        </Box>
      ))}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mt: 2,
        }}
      >
        <Button
          type="button"
          variant="contained"
          className="animated-input"
          onClick={handleAddField}
          sx={{
            backgroundColor: "#3A57E8",
            color: "white",
            fontWeight: "bold",
            borderRadius: "20px",
            padding: "5px 10px",
            mt: 2,
            "&:hover": {
              backgroundColor: "#0059b2",
            },
          }}
          disabled={!rowFieldsEditable}
        >
          Add Procedure
        </Button>

        <Button
          type="button"
          variant="contained"
          className="animated-input"
          onClick={handleSavePriorAuthorization}
          sx={{
            backgroundColor: "#3A57E8",
            color: "white",
            fontWeight: "bold",
            borderRadius: "20px",
            padding: "8px 16px",
            mt: 2,
            "&:hover": {
              backgroundColor: "#0059b2",
            },
          }}
          disabled={!rowFieldsEditable}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AddPriorAuthorization;
