import React, { useState, useEffect } from "react";

const typeColors = {
  Baseline: "blue",
  Practicing: "red",
  Mastery: "green",
  Maintenance: "purple",
};

const CustomTooltip = ({ x, y, data, chartWidth, chartHeight }) => {
  if (!data) return null;

  const tooltipWidth = 200; // Tooltip width
  const tooltipLineHeight = 22; // Height per line
  const tooltipPadding = 12; // Top and bottom padding
  const tooltipContentHeight = 260;

  // Adjust x position to prevent overflow on the right
  const adjustedX =
    x + tooltipWidth > chartWidth ? x - tooltipWidth - 10 : x + 10;

  // Adjust y position to prevent overflow at the top or bottom
  const adjustedY =
    y - tooltipContentHeight < 0
      ? y + 10 // Shift down if the tooltip would overflow above
      : y + tooltipContentHeight > chartHeight
      ? chartHeight - tooltipContentHeight - 10 // Shift up if it would overflow below
      : y - tooltipContentHeight;

  return (
    <foreignObject
      x={adjustedX}
      y={adjustedY}
      width={tooltipWidth}
      height={tooltipContentHeight}>
      <div
        style={{
          background: "rgba(0, 0, 0, 0.85)",
          color: "white",
          padding: "8px",
          borderRadius: "5px",
          fontSize: "12px",
          pointerEvents: "none",
          lineHeight: "1.5",
        }}>
        <p>
          <strong>Date:</strong> {data.date}
        </p>
        <p>
          <strong>Type:</strong> {data.type}
        </p>
        <p>
          <strong>Value:</strong> {data.result}
        </p>
        <p>
          <strong>Last Updated By:</strong> {data.lastUpdatedBy || "N/A"}
        </p>
      </div>
    </foreignObject>
  );
};

const MyResponsiveChart = ({ goalValues }) => {
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 800,
    height: 550,
  });

  // Update chart size on window resize
  useEffect(() => {
    const handleResize = () => {
      const width = Math.min(window.innerWidth * 0.9, 800);
      const height = Math.min(window.innerHeight * 0.5, 450);
      setChartDimensions({ width, height });
    };

    handleResize(); // Set initial size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { width, height } = chartDimensions;

  const formattedData = goalValues
    .map((item) => ({
      ...item,
      date: new Date(item.sessionDate).toISOString().split("T")[0],
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const minValue = Math.min(...formattedData.map((d) => d.result));
  const maxValue = Math.max(...formattedData.map((d) => d.result));

  const xMargin = 50; // Margin for axes
  const yMargin = 50;

  const scaleX = (index) =>
    xMargin + (index * (width - 2 * xMargin)) / (formattedData.length - 1);
  const scaleY = (value) =>
    height -
    yMargin -
    ((value - minValue) / (maxValue - minValue)) * (height - 2 * yMargin);

  const segments = [];
  const referenceLines = [];
  let currentSegment = [];

  formattedData.forEach((item, index) => {
    if (currentSegment.length === 0 || item.type === currentSegment[0].type) {
      currentSegment.push(item);
    } else {
      segments.push([...currentSegment]);
      currentSegment = [item];

      const midpointX = (scaleX(index - 1) + scaleX(index)) / 2;
      referenceLines.push({ x: midpointX });
    }
  });

  if (currentSegment.length > 0) {
    segments.push(currentSegment);
  }

  const xTicks = Array.from({ length: 5 }, (_, i) =>
    Math.round((i * (formattedData.length - 1)) / 4)
  );

  const yTicks = Array.from({ length: 6 }, (_, i) =>
    Math.round(minValue + (i / 5) * (maxValue - minValue))
  );

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "800px",
        margin: "auto",
      }}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        style={{ background: "#f9f9f9" }}>
        {/* Draw grid */}
        {xTicks.map((index, i) => (
          <line
            key={`grid-x-${i}`}
            x1={scaleX(index)}
            y1={yMargin}
            x2={scaleX(index)}
            y2={height - yMargin}
            stroke="#ddd"
          />
        ))}
        {yTicks.map((value, i) => (
          <line
            key={`grid-y-${i}`}
            x1={xMargin}
            y1={scaleY(value)}
            x2={width - xMargin}
            y2={scaleY(value)}
            stroke="#ddd"
          />
        ))}

        {/* Draw axes */}
        <line
          x1={xMargin}
          y1={height - yMargin}
          x2={width - xMargin}
          y2={height - yMargin}
          stroke="black"
          strokeWidth="2"
        />
        <line
          x1={xMargin}
          y1={yMargin}
          x2={xMargin}
          y2={height - yMargin}
          stroke="black"
          strokeWidth="2"
        />

        {/* Draw segments */}
        {segments.map((segment, segIndex) => (
          <polyline
            key={segIndex}
            fill="none"
            stroke={typeColors[segment[0].type]}
            strokeWidth="2"
            points={segment
              .map(
                (point, pointIndex) =>
                  `${scaleX(formattedData.indexOf(point))},${scaleY(
                    point.result
                  )}`
              )
              .join(" ")}
          />
        ))}

        {/* Draw points */}
        {formattedData.map((point, index) => (
          <circle
            key={index}
            cx={scaleX(index)}
            cy={scaleY(point.result)}
            r={formattedData.length > 15 ? 3 : 5} // Dynamic point size
            fill={typeColors[point.type]}
            onMouseEnter={() =>
              setHoveredPoint({
                x: scaleX(index),
                y: scaleY(point.result),
                data: {
                  date: point.date,
                  type: point.type,
                  result: point.result,
                  lastUpdatedBy: point.lastUpdatedBy,
                },
              })
            }
            onMouseLeave={() => setHoveredPoint(null)}
          />
        ))}

        {/* Draw reference lines */}
        {referenceLines.map((line, index) => (
          <line
            key={index}
            x1={line.x}
            y1={yMargin}
            x2={line.x}
            y2={height - yMargin}
            stroke="black"
            strokeDasharray="5,5"
          />
        ))}

        {/* Render Tooltip */}
        {hoveredPoint && (
          <CustomTooltip
            x={hoveredPoint.x}
            y={hoveredPoint.y}
            data={hoveredPoint.data}
            chartWidth={width}
            chartHeight={height}
          />
        )}

        {/* Draw x-axis labels */}
        {xTicks.map((index, i) => (
          <text
            key={`label-x-${i}`}
            x={scaleX(index)}
            y={height - yMargin + 20}
            fontSize="10"
            textAnchor="middle">
            {formattedData[index].date}
          </text>
        ))}

        {/* Draw y-axis labels */}
        {yTicks.map((value, i) => (
          <text
            key={`label-y-${i}`}
            x={xMargin - 10}
            y={scaleY(value) + 4}
            fontSize="10"
            textAnchor="end">
            {value}
          </text>
        ))}
      </svg>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}>
        {Object.entries(typeColors).map(([key, color]) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}>
            <div
              style={{
                width: "20px",
                height: "10px",
                backgroundColor: color,
                marginRight: "5px",
              }}
            />
            <strong>{key}</strong>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyResponsiveChart;
