import { useTheme, FormControlLabel, Checkbox, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import { tokens } from "../../theme";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Header from "../../components/Header";
import dayjs from "dayjs";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import useSharedLink from "../SharedLink";
import { useSnackbar } from "../../components/SnackBarProvider";
import AddIcon from "@mui/icons-material/Add";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import LoadingIndicator from "../Loader";
const roles = [
  "Executive Director",
  "Clinical Director",
  "BCBA",
  "Clinical Assistant",
  "Lead RBT",
  "BT/RBT",
];

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows = [
  {
    id: randomId(),
    name: randomTraderName(),
    age: 25,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
];

export default function Team() {
  const { openSnackbar } = useSnackbar();
  const sharedLink = useSharedLink();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const [loading, setLoading] = useState(true);
  const [info, setinfo] = useState(false);
  const [allusers, setAllusers] = useState([]);
  const [infocontent, setinfocontent] = useState("");
  const [supervisors, setsupervisors] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(false);
  const navigate = useNavigate();
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  // Helper function to format the time into "HH:MM AM/PM" (if needed)
  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    setinfo(true);
    setinfocontent("Please Wait...");

    async function fetchData(retryCount = 0) {
      try {
        const userRole = localStorage.getItem("role");

        const [staffsResponse, supervisorResponse] = await Promise.all([
          axiosInstance.post(
            sharedLink + "/viewallstaff",
            {},
            { headers: { "Content-Type": "application/json" } },
            { withCredentials: true }
          ),
          axiosInstance.post(
            sharedLink + "/roles/supervisorofrole",
            { role: userRole },
            { headers: { "Content-Type": "application/json" } },
            { withCredentials: true }
          ),
        ]);
        console.log(staffsResponse);
        const formattedRows = staffsResponse.data.map((row) => ({
          ...row,
          supervisor: row.supervisorName + " ",
        }));
        const activeUsers = formattedRows.filter(
          (row) => row.status === "Active"
        );
        setRows(activeUsers);
        setAllusers(formattedRows);
        setsupervisors(
          supervisorResponse.data.map(
            (supervisor) =>
              supervisor.firstName +
              " " +
              supervisor.lastName +
              " (" +
              supervisor.id +
              ")"
          )
        );
        console.log(supervisors, "oki");

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
    setinfo(false);
  }, []);
  const handleRowEditStop = (params, event) => {
    console.log("inside stop");
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row) => () => {
    const id = row.id;
    navigate(`/updateStaff?Id=${id}`);
  };

  const handleSaveClick = (id) => () => {
    console.log("inside save");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (row) => async () => {
    if (row.status === "Inactive") {
      openSnackbar("Cannot delete Inactive Staff.", "error");
      return;
    }
    if (window.confirm("Sure want to delete?")) {
      try {
        openSnackbar("Please Wait...", "info");
        const response = await axiosInstance.post(
          sharedLink + "/removestaff",
          { id: row.id },
          { withCredentials: true }
        );
        if (response.status === 200) {
          if (includeInactive) {
            setRows((prevRows) =>
              prevRows.map((r) =>
                r.id === row.id ? { ...r, status: "Inactive" } : r
              )
            );
          } else {
            setRows((prevRows) => prevRows.filter((r) => r.id !== row.id));
          }
          setAllusers((prevAllUsers) =>
            prevAllUsers.map((r) =>
              r.id === row.id ? { ...r, status: "Inactive" } : r
            )
          );
          openSnackbar("Information deleted.", "success");
        } else {
          openSnackbar("Failed to Delete.", "error");
        }
      } catch (error) {
        openSnackbar("Failed to Delete.", "error");
        console.error("Error while deleting item:", error);
      }
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    setinfo(true);
    setinfocontent("Please Wait...");
    const previousRow = rows.find((row) => row.id === newRow.id);

    const phoneNumberValidation = /^\d{10}$/.test(newRow.phoneNumber);
    if (!phoneNumberValidation) {
      setAlertDanger(true);
      setAlertContentDanger("Phone Number must be a 10-digit number.");
      return null;
    }

    const updatedRow = {
      ...newRow,
      dateOfBirth: dayjs(newRow.dateOfBirth).format("YYYY-MM-DD"),
      licenseExpirationDate: dayjs(newRow.licenseExpirationDate).format(
        "YYYY-MM-DD"
      ),

      lastUpdatedBy: localStorage.getItem("username"),
      lastUpdatedTime: new Date().toISOString(),
    };

    // Remove properties that should not be sent to the server
    const { isNew, ...updatePayload } = updatedRow;

    // If the password is empty, don't send it
    if (!updatePayload.password) {
      delete updatePayload.password;
    }
    delete updatePayload.enteredTime;
    delete updatePayload.lastUpdatedTime;
    delete updatePayload.reportingManager;
    // If the supervisor field is in the format "Name (ID)", extract only the ID
    if (updatePayload.supervisor.includes("(")) {
      updatePayload.supervisor = updatePayload.supervisor
        .split("(")[1]
        .split(")")[0];
    }
    console.log(updatePayload);
    try {
      const response = await axiosInstance.post(
        sharedLink + "/updatestaff",
        updatePayload,
        { withCredentials: true },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setAlert(true);
        setAlertContent("Information updated successfully.");
        return updatedRow;
      } else {
        setAlertDanger(true);
        setAlertContentDanger(
          "Failed to update. Server responded with status: " + response.status
        );
        return null;
      }
    } catch (error) {
      setAlertDanger(true);
      setAlertContentDanger("Failed to update. Error: " + error.message);
      return null;
    }

    return previousRow;
    //call api to update
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleClose = () => {
    setAlert(false);
    setAlertContent("");
  };
  const handle1Close = () => {
    setAlertContentDanger("");
    setAlertDanger(false);
  };
  const isCellEditable = (params) => {
    // Allow editing only if the status is 'Inactive' and the field is 'status'
    if (params.row.status.toLowerCase() === "active") return true;
    if (
      params.row.status.toLowerCase() === "inactive" &&
      params.field === "status"
    ) {
      return true;
    }
    return false;
  };
  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setRows(allusers);
    } else {
      setRows(allusers.filter((row) => row.status === "Active"));
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",

      editable: false,
      type: "number",
    },
    {
      field: "facilityId",
      headerName: "Facility ID",

      type: "number",
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",

      editable: false,
    },
    {
      field: "email",
      headerName: "Email",

      editable: false,
      width: 200,
    },

    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      editable: false,
      width: 150,
      type: "date",
      valueGetter: ({ row }) =>
        row.dateOfBirth ? new Date(row.dateOfBirth) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "city",
      headerName: "City",

      editable: false,
      width: 150,
    },
    {
      field: "gender",
      headerName: "Gender",

      editable: false,
      width: 150,
      type: "singleSelect",
      valueOptions: ["Prefer not to say", "Male", "Female"],
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",

      width: 200,
      editable: false,
    },

    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },

    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },

    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "licenseNumber",
      headerName: "License Number",

      width: 150,
      editable: false,
    },
    {
      field: "licenseExpirationDate",
      headerName: "License Expiration Date",

      type: "date",
      editable: false,
      width: 200,
      valueGetter: ({ row }) =>
        row.licenseExpirationDate ? new Date(row.licenseExpirationDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "npi",
      headerName: "National Provider Identifier (NPI)",

      width: 200,
      editable: false,
    },
    {
      field: "status",

      headerName: "Status",

      editable: false,
    },
    {
      field: "streetAddress",
      headerName: "Street Address",

      editable: false,
      width: 200,
    },

    {
      field: "supervisor",
      headerName: "Supervisor",

      editable: false,
    },

    {
      field: "role",
      headerName: "Role",

      editable: false,
      // type: "singleSelect",
      // valueOptions: ["Staff", "Supervisor", "Biller", "Other"],
    },
    {
      field: "country",
      headerName: "Country",

      editable: false,
    },
    {
      field: "state",
      headerName: "State",
      editable: false,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",

      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,

      editable: false,
    },
    {
      field: "title",
      headerName: "Title",

      width: 100,
    },

    {
      field: "password",
      headerName: "Password",
      editable: false,
      width: 100,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",

      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const id = params.row.id;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              title="Save"
              label="Save"
              sx={{
                color: "#3A57E8",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              title="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
            title="Edit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={params.row.status == "Inactive"}
            onClick={handleDeleteClick(params.row)}
            color="inherit"
            title="Delete"
          />,
        ];
      },
    },
  ];
  if (loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="staff-background">
        <Box m="20px">
          {alert ? (
            <Alert severity="success" onClose={handleClose}>
              {alertContent}
            </Alert>
          ) : (
            <></>
          )}
          {alertDanger ? (
            <Alert severity="error" onClose={handle1Close}>
              {alertContentDanger}
            </Alert>
          ) : (
            <></>
          )}
          {info && (
            <Alert severity="info" onClose={() => setinfo(false)}>
              {infocontent}
            </Alert>
          )}
          <Header title="Manage Staffs" subtitle="" />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Header title="" subtitle="" />
            <Button
              onClick={() => {
                navigate("/addstaff");
              }}
              variant="contained"
              startIcon={<AddIcon />} // Add Icon here
              sx={{ backgroundColor: "#3A57E8", color: "white" }}>
              Add Staff
            </Button>
          </Box>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.grey[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                borderBottom: "white",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#3A57E8",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[200]} !important`,
              },
            }}>
            {/* <DataGridPro
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            // slotProps={{
            //   toolbar: { setRows, setRowModesModel },
            // }}
            isCellEditable={(params) => params.row.status !== "Inactive"}
          /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInactive}
                  onChange={handleCheckboxChange}
                  name="includeInactive"
                />
              }
              label="Include Inactive Staff"
            />

            <DataGridPro
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                    facilityId: false,
                    lastUpdatedBy: false,
                    lastUpdatedTime: false,
                    password: false,
                    enteredTime: false,
                    enteredBy: false,
                  },
                },
              }}
              sx={{
                boxShadow: 5,
                border: 5,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .MuiDataGrid-footerContainer": {
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              onCellDoubleClick={(params, event) => {
                event.preventDefault();
              }}
              isCellEditable={(params) => isCellEditable(params)}
              onRowDoubleClick={(params) => {
                // Call the handleEditClick function or directly navigate
                handleEditClick(params.row)();
              }}
            />
          </Box>
        </Box>
      </div>
    );
  }
}
