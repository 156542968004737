import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Paper,
  IconButton,
  MenuItem,
} from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, FieldArray } from "formik";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  themeStyles,
  buttonStyle,
  MASTERY_TYPES,
  DATA_COLLECTION_TYPES,
  ROOT_FOLDER,
  TYPES,
  formatTimestamp,
} from "../../src/constants";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import useSharedLink from "../scenes/SharedLink";
import LoadingIndicator from "../scenes/Loader";
import StyledTextArea from "./StyledTextArea";
import axiosInstance from "./AxiosInstance";

const GoalComponent = ({
  goalid,
  handleGoalUpdate,
  goalvalues,
  exist,
  type,
}) => {
  console.log("goalvalues in comp", goalvalues);
  const [goalTypes, setGoalTypes] = useState([]);
  const sharedLink = useSharedLink();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    goaltype: null,
    type: type || "",
  });
  const descriptionRef = useRef(null);

  const customHandleChange = (e, handleChange, setFieldValue, values) => {
    const { name, value } = e.target;
    handleChange(e);
    handleGoalUpdate({ ...values, [name]: value });
  };

  useEffect(() => {
    const fetchInitialValues = async () => {
      const initial = {
        ...goalvalues,
        type: type || "",
        goaltype: goalvalues.goaltype
          ? goalvalues.goaltype
          : goalvalues?.goalAreaName !== "" && goalvalues?.goalAreaId !== ""
          ? {
              goalAreaName: goalvalues?.goalAreaName,
              goalAreaId: parseInt(goalvalues?.goalAreaId),
            }
          : null,
        goalState: goalvalues.goalState ?? "Baseline",
      };
      const fieldsToDelete = [
        "enteredBy",
        "lastUpdatedBy",
        "enteredTime",
        "lastUpdatedTime",
        "id",
      ];

      // Remove specified fields
      fieldsToDelete.forEach((field) => delete initial[field]);

      setInitialValues(initial);
      setLoading(false);
    };

    fetchInitialValues();
  }, [goalid, goalvalues, type]);

  useEffect(() => {
    const fetchGoalTypes = async () => {
      try {
        const res = await axiosInstance.post(sharedLink + "/viewgoalbankarea", {
          withCredentials: true,
        });
        if (res.status >= 200 && res.status < 300) {
          console.log(res, "goal banks");
          const extractGoalTypes = (node, result = []) => {
            if (
              node.goalBankAreaName !== ROOT_FOLDER &&
              node.goalBankAreaStatus == "Active"
            ) {
              result.push({
                goalAreaName: node.goalBankAreaName,
                goalAreaId: node.id.toString(),
              });
            }
            node.children?.forEach((child) => extractGoalTypes(child, result));
            return result;
          };
          const data = res?.data?.data;
          if (data) {
            const extractedGoalTypes = extractGoalTypes(data);
            setGoalTypes(extractedGoalTypes);
          }
        } else {
          console.error("Response received, but with a status error:", res);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchGoalTypes();

    const handleResize = () => {
      const textarea = descriptionRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sharedLink]);

  const prompt = [
    "Physical",
    "Pictorial",
    "Gestural",
    "Modeling",
    "Textual",
    "Verbal",
    "Positional",
    "Independent",
  ];
  const directions = ["Increasing", "Reducing"];

  const checkoutSchema = yup.object().shape({
    phonenumber: yup
      .string()
      .required("Contact number is required")
      .matches(/^[0-9]{10}$/, "Contact number must be 10 digits"),

    //other validation rules
  });
  const handleDateChange = (newValue, setFieldValue, values) => {
    if (newValue && dayjs(newValue).isValid()) {
      setFieldValue("masteryTargetDate", newValue);
      handleGoalUpdate({
        ...values,
        masteryTargetDate: newValue.format("YYYY-MM-DD"),
      });
    } else {
      console.error("Invalid date object:", newValue);
    }
  };
  if (loading) return <LoadingIndicator />;
  else {
    return (
      <Box m="20px">
        <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={checkoutSchema}
            enableReinitialize
            onSubmit={(values) => {
              handleGoalUpdate(values);
            }}>
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    ...themeStyles.textField,
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}>
                  <Autocomplete
                    fullWidth
                    disabled
                    options={goalTypes}
                    getOptionLabel={(option) =>
                      option.goalAreaName ? option.goalAreaName : ""
                    }
                    value={values.goaltype || null}
                    name="goaltype"
                    onChange={(event, newValue) => {
                      setFieldValue("goaltype", newValue ? newValue : null);
                      handleGoalUpdate({
                        ...values,
                        goaltype: newValue ? newValue : null,
                      });
                    }}
                    onBlur={handleBlur}
                    autoHighlight
                    isOptionEqualToValue={(option, value) =>
                      parseFloat(option.goalAreaId) ===
                      parseFloat(value.goalAreaId)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Goal Area"
                        variant="filled"
                        required
                        disabled={initialValues.goalStatus === "Inactive"}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Goal type",
                        }}
                      />
                    )}
                    sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Goal"
                    required
                    onBlur={handleBlur}
                    onChange={(e) =>
                      customHandleChange(e, handleChange, setFieldValue, values)
                    }
                    disabled={initialValues.goalStatus === "Inactive"}
                    value={values.goalName}
                    name="goalName"
                    inputProps={{
                      maxLength: 120,
                    }}
                    sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                  />

                  <Autocomplete
                    fullWidth
                    options={TYPES}
                    autoHighlight
                    value={values.programType}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "programType",
                        newValue !== null ? newValue : null
                      );
                      handleGoalUpdate({
                        ...values,
                        programType: newValue !== null ? newValue : null,
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Program Type"
                        variant="filled"
                        required
                        disabled={initialValues.goalStatus === "Inactive"}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select type",
                        }}
                      />
                    )}
                    sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                  />

                  <StyledTextArea
                    fullWidth
                    variant="filled"
                    multiline
                    label="Description"
                    onBlur={handleBlur}
                    disabled={initialValues.goalStatus === "Inactive"}
                    onChange={(event) => {
                      handleChange(event);
                      const textarea = descriptionRef.current;
                      if (textarea) {
                        textarea.style.height = "0";
                        textarea.style.height = `${textarea.scrollHeight}px`;
                      }
                      handleGoalUpdate({
                        ...values,
                        goalDescription: event.target.value,
                      });
                    }}
                    value={values.goalDescription}
                    required
                    name="goalDescription"
                    rows={3}
                    inputRef={descriptionRef}
                    maxLength={200}
                  />
                  {type == "book" && (
                    <Autocomplete
                      options={DATA_COLLECTION_TYPES}
                      getOptionLabel={(option) => option}
                      value={values.goalState}
                      autoHighlight={true}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "goalState",
                          newValue !== null ? newValue : null
                        );
                        handleGoalUpdate({
                          ...values,
                          goalState: newValue !== null ? newValue : null,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Data Collection Type"
                          variant="filled"
                          required
                          fullWidth
                        />
                      )}
                      sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      disabled={initialValues.goalStatus === "Inactive"}
                    />
                  )}
                  <Autocomplete
                    fullWidth
                    options={directions}
                    autoHighlight
                    value={values.masteryDirection}
                    disabled={initialValues.goalStatus === "Inactive"}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "masteryDirection",
                        newValue !== null ? newValue : null
                      );
                      handleGoalUpdate({
                        ...values,
                        masteryDirection: newValue !== null ? newValue : null,
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mastery Direction"
                        variant="filled"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select mastery direction",
                        }}
                      />
                    )}
                    sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={
                        type === "book"
                          ? "Mastery Target Date *"
                          : "Mastery Target Date"
                      }
                      value={
                        values.masteryTargetDate
                          ? dayjs(values.masteryTargetDate)
                          : null
                      }
                      onChange={(newValue) =>
                        handleDateChange(newValue, setFieldValue, values)
                      }
                      minDate={dayjs()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          required
                          error={
                            touched.masteryTargetDate &&
                            Boolean(errors.masteryTargetDate)
                          }
                          helperText={
                            touched.masteryTargetDate &&
                            errors.masteryTargetDate
                          }
                        />
                      )}
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    />
                  </LocalizationProvider>

                  <Autocomplete
                    fullWidth
                    options={MASTERY_TYPES}
                    autoHighlight
                    className="animated-input"
                    value={values.masteryType}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "masteryType",
                        newValue !== null ? newValue : ""
                      );
                      handleGoalUpdate({
                        ...values,
                        masteryType: newValue !== null ? newValue : "",
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mastery Type"
                        variant="filled"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select mastery type",
                        }}
                      />
                    )}
                    error={touched.masteryType && Boolean(errors.masteryType)}
                    helperText={touched.masteryType && errors.masteryType}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Attempts"
                    onBlur={handleBlur}
                    required
                    onChange={(e) =>
                      customHandleChange(e, handleChange, setFieldValue, values)
                    }
                    disabled={initialValues.goalStatus === "Inactive"}
                    value={values.noOfAttempts}
                    inputProps={{ min: "0" }}
                    name="noOfAttempts"
                    sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                  />

                  {values.programType === "Percentage" && (
                    <>
                      <Autocomplete
                        fullWidth
                        options={prompt}
                        autoHighlight
                        value={values.prompt}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "prompt",
                            newValue !== null ? newValue : null
                          );
                          handleGoalUpdate({
                            ...values,
                            prompt: newValue !== null ? newValue : null,
                          });
                        }}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Prompt"
                            variant="filled"
                            required
                            disabled={initialValues.goalStatus === "Inactive"}
                            inputProps={{
                              ...params.inputProps,
                              "aria-label": "Select prompt",
                            }}
                          />
                        )}
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Mastery Criteria, %"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryCriteria}
                        name="masteryCriteria"
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Mastery Duration"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryDuration}
                        name="masteryDuration"
                        required
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />
                    </>
                  )}

                  {values.programType === "Frequency" && (
                    <>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Mastery Criteria, times"
                        onBlur={handleBlur}
                        required
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryCriteria}
                        name="masteryCriteria"
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Mastery Duration"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryDuration}
                        name="masteryDuration"
                        required
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />
                    </>
                  )}

                  {values.programType === "Duration" && (
                    <>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Mastery Criteria, seconds"
                        onBlur={handleBlur}
                        required
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryCriteria}
                        name="masteryCriteria"
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Mastery Duration"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryDuration}
                        name="masteryDuration"
                        required
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />
                    </>
                  )}

                  {values.programType === "Task Analysis" && (
                    <>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="number"
                        label="Mastery Criteria, %"
                        onBlur={handleBlur}
                        required
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryCriteria}
                        name="masteryCriteria"
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Mastery Duration"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          customHandleChange(
                            e,
                            handleChange,
                            setFieldValue,
                            values
                          )
                        }
                        disabled={initialValues.goalStatus === "Inactive"}
                        value={values.masteryDuration}
                        name="masteryDuration"
                        required
                        sx={{ ...themeStyles.textField, gridColumn: "span 2" }}
                      />

                      <FieldArray name="trialList">
                        {({ form, push, remove }) => (
                          <Box
                            display="grid"
                            gap="20px"
                            sx={{
                              ...themeStyles.textField,
                              gridColumn: "span 4",
                            }}
                            gridTemplateColumns="0fr 1fr 3fr auto">
                            {values.trialList?.map((trial, index) => (
                              <React.Fragment key={index}>
                                <StyledTextArea
                                  name={`trialList.${index}.trialName`}
                                  label="Trial Name"
                                  value={trial.trialName}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `trialList.${index}.trialName`,
                                      e.target.value
                                    );
                                    const updatedValues = {
                                      ...values,
                                      trialList: values.trialList.map(
                                        (item, idx) =>
                                          idx === index
                                            ? {
                                                ...item,
                                                trialName: e.target.value,
                                              }
                                            : item
                                      ),
                                    };
                                    handleGoalUpdate(updatedValues);
                                  }}
                                  disabled={
                                    initialValues.goalStatus === "Inactive"
                                  }
                                  sx={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                  select
                                  fullWidth
                                  variant="filled"
                                  name={`trialList.${index}.prompt`}
                                  label="Prompt"
                                  value={trial.prompt}
                                  onChange={(e) => {
                                    const newPrompt = e.target.value;
                                    setFieldValue(
                                      `trialList.${index}.prompt`,
                                      newPrompt
                                    );
                                    const updatedValues = {
                                      ...values,
                                      trialList: values.trialList.map(
                                        (item, idx) =>
                                          idx === index
                                            ? { ...item, prompt: newPrompt }
                                            : item
                                      ),
                                    };
                                    handleGoalUpdate(updatedValues);
                                  }}
                                  disabled={
                                    initialValues.goalStatus === "Inactive"
                                  }
                                  sx={{ gridColumn: "span 1" }}>
                                  {prompt.map((option, optionIndex) => (
                                    <MenuItem key={optionIndex} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                <IconButton
                                  onClick={() => {
                                    const updatedTrialList =
                                      values.trialList.filter(
                                        (_, filterIndex) =>
                                          filterIndex !== index
                                      );
                                    setFieldValue(
                                      "trialList",
                                      updatedTrialList
                                    );
                                    handleGoalUpdate({
                                      ...values,
                                      trialList: updatedTrialList,
                                    });
                                  }}
                                  disabled={
                                    initialValues.goalStatus === "Inactive"
                                  }
                                  sx={{
                                    boxShadow: "none",
                                    "&:hover": {
                                      boxShadow: "none",
                                      backgroundColor: "transparent",
                                    },
                                  }}>
                                  <DeleteIcon
                                    sx={{
                                      boxShadow: "none",
                                      "&:hover": {
                                        boxShadow: "none",
                                      },
                                    }}
                                  />
                                </IconButton>
                              </React.Fragment>
                            ))}
                            <Button
                              type="button"
                              variant="filled"
                              onClick={() => {
                                const updatedTrialList = [
                                  ...(values.trialList || []),
                                  { trialName: "", prompt: "" },
                                ];
                                form.setFieldValue(
                                  "trialList",
                                  updatedTrialList
                                );
                                handleGoalUpdate({
                                  ...values,
                                  trialList: updatedTrialList,
                                });
                              }}
                              sx={{ ...buttonStyle }}
                              disabled={
                                initialValues.goalStatus === "Inactive"
                              }>
                              Add Option
                            </Button>
                          </Box>
                        )}
                      </FieldArray>
                    </>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Box>
    );
  }
};

export default GoalComponent;
