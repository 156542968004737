import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Paper,
  InputAdornment,
  Grid,
  Autocomplete,
  Tooltip,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axiosInstance from "../../components/AxiosInstance";
import useSharedLink from "../../scenes/SharedLink";
import { useSnackbar } from "../../components/SnackBarProvider";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Import for play icon
import StopIcon from "@mui/icons-material/Stop";
import { DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";

import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
import dayjs from "dayjs";
import {
  DATA_COLLECTION_TYPES,
  DATA_ENTRY_PAGE,
  PROMPTS,
  modalStyle,
  themeStyles,
  buttonStyle,
  SESSION_NOTE_97153,
  CREATE,
  READ,
  UPDATE,
  DATA_COLLECTION_TYPE,
} from "../../constants"; // adjust the import path if necessary
import PauseIcon from "@mui/icons-material/Pause";
import LoadingIndicator from "../../scenes/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import PopUp from "../../components/PopUp";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Modal from "@mui/material/Modal";
import GoalsLineGraphs from "../GoalLineGraph"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../Phase3/CheckPermission";
const GoalItem = ({
  goal,
  goals,
  clientId,
  clientName,
  sessionId,
  sessionDate,
  owner,
}) => {
  console.log(goal, "goal in item");
  const { checkPermission, CheckDataCollectionPermission } = usePermissions();
  const editDisable = checkPermission(DATA_ENTRY_PAGE, "u", owner) == false;
  const addDisable = checkPermission(DATA_ENTRY_PAGE, "c", owner) == false;
  const deleteDisable = checkPermission(DATA_ENTRY_PAGE, "d", owner) == false;

  const navigate = useNavigate();
  const [attemptValues, setAttemptValues] = useState([]);
  const [shouldRender, setShouldRender] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [dataCollectionType, setDataCollectionType] = useState(
    goal.goalState ?? "Baseline"
  );
  const [selectedDate, setSelectedDate] = useState(
    dayjs(sessionDate || new Date())
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [readDisable, setReadDisable] = useState(false);
  const [timers, setTimers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState("add");
  const sharedLink = useSharedLink();
  const [isGraphModalOpen, setGraphModalOpen] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const programtype =
    goal?.programType?.toLowerCase() === "task analysis"
      ? "taskanalysis"
      : goal.programType.toLowerCase();
  const [editableIndexes, setEditableIndexes] = useState(
    attemptValues.map(() => false)
  );
  const [trialInterval, setTrialInterval] = useState(1);
  const handleGraphModalOpen = () => setGraphModalOpen(true);
  const handleGraphModalClose = () => setGraphModalOpen(false);

  useEffect(() => {
    setLoading(true);
    console.log(
      sessionDate,
      "data collection type",
      CheckDataCollectionPermission(DATA_COLLECTION_TYPE)
    );

    const endpoint = "/getraw" + programtype + "data";

    const resetStateBasedOnProgramType = () => {
      if (addDisable) {
        openSnackbar(
          "There is no data saved in this selected date for the selected goal",
          "info"
        );
        return;
      }
      switch (goal.programType.toLowerCase()) {
        case "task analysis":
          setAttemptValues(
            goal?.trialDataList.map((trial) => ({
              trialName: trial.trialName,
              prompt: trial.prompt,
              value: "",
              reason: "",
            }))
          );
          setTrialInterval(goal.trialDataList.length);
          break;
        case "percentage":
          setAttemptValues(
            Array(goal?.noOfAttempts).fill({ reason: "", value: "" })
          );
          break;
        case "frequency":
          setAttemptValues(Array(goal?.noOfAttempts).fill(""));
          break;
        case "duration":
          setTimers(
            Array(goal?.noOfAttempts).fill({
              startTime: 0,
              isRunning: false,
              elapsed: 0,
              value: "0s",
            })
          );
          setEditableIndexes(timers.map(() => false));
          break;
        default:
          setAttemptValues([""]);
      }
    };
    console.log(goal);
    const payload = {
      clientid: clientId,
      sessionstartdate: dayjs(sessionDate).format("YYYY-MM-DD"),
      sessionenddate: dayjs(sessionDate).format("YYYY-MM-DD"),
      goalid: goal.goalId,
    };
    try {
      axiosInstance
        .post(sharedLink + endpoint, payload)
        .then((res) => {
          console.log("response", res);
          if (res.data && res.data.length > 0) {
            const data = res.data[0];
            console.log(data, "data");
            setId(data.id);
            if (
              !checkPermission(
                DATA_ENTRY_PAGE,
                READ,
                data.enteredBy == localStorage.getItem("userId")
                  ? "Self"
                  : "Other"
              )
            ) {
              openSnackbar(
                "There is already data present for this date",
                "info"
              );
              setReadDisable(true);
              return;
            }
            setReadDisable(false);

            console.log(goal.programType.trim().toLowerCase(), "programtype");

            if (goal.programType.toLowerCase() === "frequency") {
              const updatedValues = data.frequencyDataSetList.map((item) => ({
                trialSequence: item.trialSequence,
                value: item["frequency"].toString(),
              }));
              setAttemptValues(updatedValues);
            } else if (goal.programType.toLowerCase() === "duration") {
              const updatedTimers = data.durationDataSetList.map((item) => ({
                isRunning: false,
                startTime: 0,
                elapsed: item.duration,
                value: item.duration + "s",
              }));

              setTimers(updatedTimers);
              setEditableIndexes(updatedTimers.map(() => false));
            } else if (goal.programType.trim().toLowerCase() === "percentage") {
              const updatedValues = data.percentDataSet.map((item) => ({
                trialSequence: item.trialSequence,
                value: (parseInt(item["percent"], 10) * 100).toString(),
                reason: item?.prompt,
              }));
              console.log(updatedValues, "updatedValues");
              setAttemptValues(updatedValues);
            } else if (
              goal.programType.trim().toLowerCase() == "task analysis"
            ) {
              if (data.trialDataSetList.length > 0) {
                setTrialInterval(data.trialDataSetList[0].trialDataList.length);
              }
              const updatedValues = data.trialDataSetList.reduce(
                (acc, dataSet) => {
                  const results = dataSet.trialDataList.map((trial) => ({
                    trialName: trial.trialName,
                    prompt: trial.prompt,
                    value: parseInt(trial.result, 10) * 100 + "",
                    reason: trial.prompt,
                  }));
                  return [...acc, ...results];
                },
                []
              );
              console.log(updatedValues, "updatedValues");

              setAttemptValues(updatedValues);
            }
            setDataCollectionType(
              res?.data[0]?.goalDataCommon?.dataCollectionType
            );

            setOperation("update");
          } else {
            setOperation("add");

            resetStateBasedOnProgramType();
          }
        })
        .catch((error) => {
          setOperation("add");
          resetStateBasedOnProgramType();
        })
        .finally(() => {
          setLoading(false);
          console.log(operation);

          setShouldRender(true);
        });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }, [goal, sessionDate]);
  useEffect(() => {
    if (dataCollectionType) {
      setAttemptValues((currentValues) =>
        currentValues.map((attempt) => {
          // Check if the data collection type is 'Baseline' and the value is '0'
          const isBaseline = dataCollectionType === "Baseline";
          const shouldSetIncorrect = isBaseline && attempt.value === "0";

          return {
            ...attempt,
            // Set reason to 'Incorrect' if conditions are met, otherwise keep existing or clear it
            reason: shouldSetIncorrect
              ? "Incorrect"
              : PROMPTS.includes(attempt.reason)
              ? attempt.reason
              : "",
          };
        })
      );
    }
  }, [dataCollectionType]);

  const handleCheckClick = (index) => {
    setAttemptValues((currentValues) =>
      currentValues.map((item, idx) =>
        idx === index ? { ...item, value: "100", reason: "Independent" } : item
      )
    );
  };
  const handleEditDisable = () => {
    return (
      (operation == "update" && editDisable) ||
      (operation == "add" && addDisable) ||
      readDisable
    );
  };
  const handleCancelClick = (index) => {
    setAttemptValues((currentValues) =>
      currentValues.map((item, idx) =>
        idx === index
          ? {
              ...item,
              value: "0",
              reason: dataCollectionType === "Baseline" ? "Incorrect" : "",
            }
          : item
      )
    );
  };

  const startTimer = (index) => {
    setTimers((prevTimers) => {
      return prevTimers.map((timer, idx) => {
        if (idx === index && !timer.isRunning) {
          const newStartTime = Date.now() - timer.elapsed * 1000; // Recalculate start time based on elapsed to handle pause correctly
          const newTimer = {
            ...timer,
            isRunning: true,
            startTime: newStartTime,
            intervalId: setInterval(() => {
              setTimers((currentTimers) => {
                const updatedTimers = [...currentTimers];
                updatedTimers[idx].elapsed = Math.round(
                  (Date.now() - updatedTimers[idx].startTime) / 1000
                );
                updatedTimers[idx].value = `${updatedTimers[idx].elapsed}s`;
                return updatedTimers;
              });
            }, 1000),
          };
          return newTimer;
        }
        return timer;
      });
    });
  };

  const pauseTimer = (index) => {
    setTimers((prevTimers) => {
      return prevTimers.map((timer, idx) => {
        if (idx === index && timer.isRunning) {
          clearInterval(timer.intervalId);
          return { ...timer, isRunning: false, intervalId: null };
        }
        return timer;
      });
    });
  };

  const stopTimer = (index) => {
    setTimers((prevTimers) => {
      return prevTimers.map((timer, idx) => {
        if (idx === index) {
          clearInterval(timer.intervalId);
          return {
            ...timer,
            isRunning: false,
            wasStopped: true,
            intervalId: null,
            elapsed: 0,
            // value: "0s",
          };
        }
        return timer;
      });
    });
  };

  const handleDateChange = async (date) => {
    setSelectedDate(dayjs(date));
    console.log(attemptValues, "date");
  };
  const handleDelete = async () => {
    const endpoint = "/removeraw" + programtype + "data";
    try {
      axiosInstance
        .post(`${sharedLink}${endpoint}`, {
          id: id,
        })
        .then((res) => {
          if (res.status == 200) {
            openSnackbar("Successfully deleted!", "success");
            setAttemptValues([]);
            setTimers([]);
            setDataCollectionType("");
            setSelectedDate(null);
            setOperation("add");
          } else {
            openSnackbar(res?.data, "error");
          }
        })
        .catch((error) => {
          openSnackbar(error?.response?.data, "error");
        });
    } catch (error) {
      console.error("Error deleting item:", error);
      openSnackbar(error?.response?.data, "error");
    }
  };

  const handleAttemptUpdate = (index, value) => {
    if (goal.programType.toLowerCase() === "duration") {
      const updatedValues = [...timers];
      updatedValues[index] = { ...updatedValues[index], value };
      setTimers(updatedValues);
      return;
    }

    setAttemptValues((currentValues) =>
      currentValues.map((item, idx) =>
        idx === index ? { ...item, value: value } : item
      )
    );
  };

  const handleAddAttempt = () => {
    switch (goal.programType.toLowerCase()) {
      case "task analysis":
        console.log(goal, "goal");

        setAttemptValues((prevValues) => [
          ...prevValues,
          ...goal.trialDataList.map((trial) => ({
            ...trial,
            reason: "",
            value: "",
          })),
        ]);

        break;
      case "percentage":
        setAttemptValues((prevValues) => [
          ...prevValues,
          { reason: "", value: "" },
        ]);
        break;
      case "duration":
        const newTimer = { startTime: 0, isRunning: false, elapsed: 0 };
        setTimers((prevTimers) => [...prevTimers, newTimer]);
        setEditableIndexes((current) => [...current, false]);

        break;
      case "frequency":
        setAttemptValues((prevValues) => [...prevValues, ""]);
        break;
      default:
        setAttemptValues((prevValues) => [...prevValues, ""]);
    }
  };

  const handleRemoveAttempt = (index) => {
    if (goal.programType.toLowerCase() === "task analysis") {
      const updatedValues = attemptValues.filter(
        (_, i) => i < index || i >= index + trialInterval
      );
      setAttemptValues(updatedValues);
    } else if (goal.programType.toLowerCase() == "duration") {
      clearInterval(timers[index].intervalId);
      const updatedValues = timers.filter((_, i) => i !== index);
      setEditableIndexes((currentIndexes) =>
        currentIndexes.filter((_, idx) => idx !== index)
      );

      setTimers(updatedValues);
    } else {
      console.log(index);
      const updatedValues = attemptValues.filter((_, i) => i !== index);
      setAttemptValues(updatedValues);
    }
  };
  const fillAttempt = (index, value) => {
    if (goal.programType.toLowerCase() === "task analysis") {
      const newAttempts = [...attemptValues];
      newAttempts[index] = { ...newAttempts[index], value };
      setAttemptValues(newAttempts);
    } else if (goal.programType.toLowerCase() == "duration") {
      setTimers((prevTimers) => {
        const newTimers = [...prevTimers];
        const timer = newTimers[index];
        clearInterval(timer.intervalId); // Clear the interval to stop the timer
        timer.isRunning = false;
        timer.wasStopped = true;
        timer.elapsed = 0;
        timer.value = "0s";
        return newTimers;
      });
    } else {
      console.log("hu");
      const newAttempts = [...attemptValues];
      newAttempts[index] = { ...newAttempts[index], value };
      setAttemptValues(newAttempts);
    }
  };

  const handleIncrement = (index) => {
    setAttemptValues(
      attemptValues.map((val, idx) => {
        if (idx == index && val.value == undefined)
          return { ...val, value: String(0) };
        else if (idx === index) {
          return { ...val, value: String(Number(val.value) + 1) }; // Ensure value is a string
        }

        return val;
      })
    );
  };
  const handleSessionNoteClick = () => {
    console.log(
      clientId,
      "client id",
      sessionId,
      "sesion id",
      sessionDate,
      "sessionDate"
    );
    let id = "";
    const payload = {
      clientid: clientId,
      sessionstartdate: dayjs(sessionDate).format("YYYY-MM-DD"),
      sessionenddate: dayjs(sessionDate).format("YYYY-MM-DD"),
      procedurecode: "97153",
    };

    console.log("payload", payload);
    try {
      axiosInstance
        .post(sharedLink + "/viewasessionnote", payload)
        .then((res) => {
          console.log("response", res);
          if (res.status === 200) {
            if (res?.data?.length === 1) {
              if (checkPermission(SESSION_NOTE_97153, "r"))
                navigate(
                  `/updateSessionNote97153?sessionId=${res.data[0].sessionId}`,
                  {
                    state: { owner },
                  }
                );
            } else {
              openSnackbar(
                "There is no Session Note associated in the selected date",
                "error"
              );
              if (checkPermission(SESSION_NOTE_97153, CREATE, owner))
                navigate(
                  `/sessionNote?date=${dayjs(selectedDate).format(
                    "YYYY-MM-DD"
                  )}&clientid=${clientId}&from=data collection`,
                  {
                    state: { owner },
                  }
                );
            }
          }
        })
        .catch((err) => {
          openSnackbar(err, "error");
          console.log(err);
        });
    } catch (err) {}
    //
  };
  const handleDoneClick = () => {
    if (!dataCollectionType) {
      openSnackbar("Please select data collection type.", "error");
      return;
    }
    const isInvalidValuePresent = attemptValues.some((attempt) =>
      isNaN(parseInt(attempt.value, 10))
    );
    const isInvalidReasonPresent =
      goal.programType.toLowerCase() === "task analysis" ||
      goal.programType.toLowerCase() === "percentage"
        ? attemptValues.some((attempt) => !attempt.reason)
        : false;

    if (isInvalidReasonPresent) {
      openSnackbar("Please select a reason for all attempts.", "error");
      return; // Exit the function early to prevent further actions
    }

    const isInvalidTimerPresent =
      programtype === "duration" &&
      timers.some((timer) => isNaN(timer.elapsed));

    if (isInvalidValuePresent || isInvalidTimerPresent) {
      openSnackbar("Please fill all the attempts properly.", "error");
      return;
    }

    let submissionData;

    const goalDataCommon = {
      clientId: clientId,
      clientName: clientName,
      sessionDate: dayjs(selectedDate).format("YYYY-MM-DD"),
      sessionID: sessionId,
      dataCollectionType: dataCollectionType,

      goalId: goal.goalId,
      goalName: goal.goalName,
      programType: goal.programType,
      masteryTargetDate: goal.masteryTargetDate
        ? goal.masteryTargetDate
        : sessionDate,

      lastUpdatedBy: localStorage.getItem("name"),
      enteredBy: localStorage.getItem("name"),
    };

    switch (goal.programType.toLowerCase()) {
      case "task analysis":
        const groupedAttempts = [];
        for (let i = 0; i < attemptValues.length; i += trialInterval) {
          groupedAttempts.push({
            trialSequence: Math.floor(i / trialInterval) + 1,
            trialDataList: attemptValues
              .slice(i, i + trialInterval)
              .map((attempt) => ({
                trialName: attempt.trialName,
                prompt: attempt.reason,
                result: parseInt(attempt.value, 10) / 100,
              })),
          });
        }
        submissionData = {
          goalDataCommon,
          trialDataSetList: JSON.stringify(groupedAttempts),
        };
        break;
      case "percentage":
        submissionData = {
          goalDataCommon,
          percentDataSet: JSON.stringify(
            attemptValues.map((attempt, index) => ({
              trialSequence: index + 1,
              percent: parseInt(attempt.value, 10) / 100,
              prompt: attempt.reason,
            }))
          ),
        };
        break;
      case "duration":
        submissionData = {
          goalDataCommon,
          durationDataSetList: JSON.stringify(
            timers.map((attempt, index) => ({
              trialSequence: index + 1,
              [goal.programType.toLowerCase()]:
                parseInt(attempt.value, 10) || 0,
            }))
          ),
        };
        break;
      case "frequency":
        submissionData = {
          goalDataCommon,
          frequencyDataSetList: JSON.stringify(
            attemptValues.map((attempt, index) => ({
              trialSequence: index + 1,
              [goal.programType.toLowerCase()]: parseInt(attempt.value, 10),
            }))
          ),
        };
        break;
      default:
        console.warn("Unsupported program type");
        return;
    }

    // Replace console.log with your API submission call
    console.log("Submission Data:", submissionData);
    if (operation === "update") {
      submissionData.id = id;
    }
    const endpoint = "/" + operation + "raw" + programtype + "data";
    try {
      axiosInstance
        .post(sharedLink + endpoint, submissionData)
        .then((response) => {
          console.log("API Response:", response.data);
          // Optionally, show a success message via Snackbar
          openSnackbar(
            "SuccessFully " +
              operation +
              (operation == "add" ? "ed" : "d!") +
              "",
            "success"
          );
        })
        .catch((error) => {
          console.error("API Error:", error);
          const errorMessage =
            error.response && error.response.data
              ? error.response.data
              : "Something went wrong";
          openSnackbar(errorMessage, "error");
        });
    } catch (error) {}
  };

  const handleDecrement = (index) => {
    setAttemptValues(
      attemptValues.map((val, idx) => {
        if (idx === index && Number(val.value) > 0) {
          return { ...val, value: String(Number(val.value) - 1) };
        } else if (idx == index && val.value == undefined)
          return { ...val, value: String(0) };
        return val;
      })
    );
  };
  const enableEditing = (index) => {
    console.log(editableIndexes, "editable", index);
    setEditableIndexes((current) =>
      current.map((editable, idx) => {
        console.log(idx, " ", index);
        if (parseInt(idx, 10) === parseInt(index, 10)) {
          return !editable;
        }

        return editable;
      })
    );
    console.log(editableIndexes, "after");
  };

  const updateValue = (index, newValue) => {
    if (goal.programType.toLowerCase() === "duration") {
      const numericValue = newValue.replace("s", "");
      setTimers((currentTimers) => {
        const updatedTimers = [...currentTimers];
        const timer = updatedTimers[index];
        timer.value = `${numericValue}s`;
        timer.elapsed = parseInt(numericValue, 10) || 0;
        return updatedTimers;
      });
      return;
    }

    setAttemptValues((currentValues) =>
      currentValues.map((value, idx) =>
        idx === index ? { ...value, value: newValue } : value
      )
    );
  };
  const handlePrimaryAction = () => {
    handleDelete();
    setOpen(false);
  };
  const handleSecondaryAction = () => {
    console.log("Secondary action performed");
    setOpen(false);
  };
  const renderAttempts = () => {
    switch (goal.programType.toLowerCase()) {
      case "task analysis":
        return (
          <Grid item xs={8} md={6} lg={4}>
            {attemptValues.map((attempt, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                      Trial:
                    </Typography>
                    <Typography sx={{ mr: 2 }}>{attempt.trialName}</Typography>
                    <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                      Prompt:
                    </Typography>
                    <Typography>{attempt.prompt}</Typography>
                  </Box>
                  {index % trialInterval == 0 && (
                    <IconButton
                      color="error"
                      disabled={handleEditDisable()}
                      onClick={() => handleRemoveAttempt(index)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    color="success"
                    disabled={handleEditDisable()}
                    onClick={() => handleCheckClick(index)}>
                    <CheckCircleIcon />
                  </IconButton>
                  <TextField
                    size="small"
                    variant="filled"
                    disabled={handleEditDisable()}
                    value={attempt.value}
                    onChange={(e) => handleAttemptUpdate(index, e.target.value)}
                    sx={{ mx: 1, flexGrow: 1, ...themeStyles.textField }}
                  />
                  <IconButton
                    color="error"
                    disabled={handleEditDisable()}
                    onClick={() => handleCancelClick(index)}>
                    <CancelIcon />
                  </IconButton>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    variant="filled"
                    id={`autocomplete-reason-${index}`}
                    options={
                      attempt.value === "100"
                        ? ["Independent"]
                        : dataCollectionType === "Baseline"
                        ? ["Incorrect"]
                        : PROMPTS.filter((prompt) => prompt !== "Independent")
                    }
                    value={attempt.reason}
                    onChange={(event, newValue) => {
                      // Ensure the current state is correctly updated with the new reason
                      // Here, we are assuming attemptValues is an array of objects each with a 'reason' property
                      setAttemptValues((currentValues) =>
                        currentValues.map((item, idx) =>
                          idx === index ? { ...item, reason: newValue } : item
                        )
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Reason"
                        variant="filled"
                      />
                    )}
                    disabled={
                      !dataCollectionType ||
                      !attempt.value ||
                      handleEditDisable()
                    } // Disable if no data collection type is selected
                    sx={{ width: 200, ml: 1, ...themeStyles.textField }}
                  />
                </Box>
              </Box>
            ))}
            ;
          </Grid>
        );
      case "percentage":
        return attemptValues.map((attempt, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}>
            <IconButton
              color="success"
              disabled={handleEditDisable()}
              onClick={() => handleCheckClick(index)}>
              <CheckCircleIcon />
            </IconButton>
            <TextField
              size="small"
              variant="filled"
              disabled={handleEditDisable()}
              value={attempt.value}
              onChange={(e) => handleAttemptUpdate(index, e.target.value)}
              sx={{ mx: 1, flexGrow: 1, ...themeStyles.textField }}
            />
            <IconButton
              color="error"
              disabled={handleEditDisable()}
              onClick={() => handleCancelClick(index)}>
              <CancelIcon />
            </IconButton>
            <Autocomplete
              autoHighlight={true}
              id={`autocomplete-reason-${index}`}
              options={
                attempt.value === "100"
                  ? ["Independent"]
                  : dataCollectionType === "Baseline"
                  ? ["Incorrect"]
                  : PROMPTS.filter((prompt) => prompt !== "Independent")
              }
              value={attempt.reason}
              onChange={(event, newValue) => {
                // Ensure the current state is correctly updated with the new reason
                // Here, we are assuming attemptValues is an array of objects each with a 'reason' property
                setAttemptValues((currentValues) =>
                  currentValues.map((item, idx) =>
                    idx === index ? { ...item, reason: newValue } : item
                  )
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Reason"
                  variant="filled"
                  fullWidth
                />
              )}
              disabled={
                !dataCollectionType || !attempt.value || handleEditDisable()
              } // Disable if no data collection type is selected
              sx={{ width: 200, ml: 1, ...themeStyles.textField }}
            />

            {/* New Remove IconButton */}
            <IconButton
              color="error"
              disabled={handleEditDisable()}
              onClick={() => handleRemoveAttempt(index)}
              sx={{ ml: 1 }} // Add margin left to separate it from the cancel icon
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ));

      case "duration":
        return (
          <Grid item xs={8} md={6} lg={8}>
            {timers.map((attempt, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}>
                <IconButton
                  color="primary"
                  onClick={() => startTimer(index)}
                  disabled={timers[index]?.isRunning || handleEditDisable()}
                  sx={{ color: timers[index]?.isRunning ? "grey" : "red" }}>
                  <PlayArrowIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => pauseTimer(index)} // This function should pause the timer
                  disabled={!timers[index]?.isRunning || handleEditDisable()}>
                  <PauseIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => stopTimer(index)}
                  disabled={!timers[index]?.isRunning || handleEditDisable()}>
                  <StopIcon />
                </IconButton>

                <TextField
                  size="small"
                  variant="filled"
                  fullWidth
                  disabled={handleEditDisable()}
                  value={
                    attempt.isRunning
                      ? `${attempt.elapsed || 0}s`
                      : attempt.value
                  }
                  onChange={(e) => {
                    // This callback is only for editable text fields
                    if (editableIndexes[index]) {
                      const newValue = e.target.value.replace(/[^0-9]/g, "");
                      updateValue(index, `${newValue}s`);
                    }
                  }}
                  InputProps={{
                    readOnly: !editableIndexes[index],
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            enableEditing(index);
                            if (!editableIndexes[index]) {
                              // Update the value to reflect the last elapsed time if it was running
                              updateValue(
                                index,
                                `${timers[index].elapsed || 0}s`
                              );
                            }
                          }}
                          size="small">
                          {editableIndexes[index] ? (
                            <CheckCircleIcon />
                          ) : (
                            <EditIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ ...themeStyles.textField }}
                />
                <IconButton
                  color="error"
                  disabled={handleEditDisable()}
                  onClick={() => fillAttempt(index, "0s")}>
                  <CancelIcon />
                </IconButton>

                <IconButton
                  color="error"
                  disabled={handleEditDisable()}
                  onClick={() => handleRemoveAttempt(index)}
                  sx={{ ml: 1 }}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            ))}
            ;
          </Grid>
        );

      case "frequency":
        console.log("attemptValuesduring render", attemptValues);
        return attemptValues.map((attempt, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}>
            <IconButton
              color="primary"
              disabled={handleEditDisable()}
              onClick={() => handleIncrement(index)}
              sx={{
                borderRadius: "30%",
                border: "1px solid black",
                padding: "1px",
              }}>
              <AddIcon />
            </IconButton>

            <TextField
              size="small"
              variant="filled"
              value={attempt.value}
              disabled={handleEditDisable()}
              onChange={(e) => handleAttemptUpdate(index, e.target.value)}
              sx={{ mx: 1, flexGrow: 1, ...themeStyles.textField }}
              type="number"
              inputProps={{ min: 0 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
            <IconButton
              color="secondary"
              disabled={handleEditDisable()}
              onClick={() => handleDecrement(index)}
              sx={{
                borderRadius: "30%",
                border: "1px solid black",
                padding: "1px",
                ...themeStyles.textField,
              }}>
              <RemoveIcon />
            </IconButton>

            <IconButton
              color="error"
              disabled={handleEditDisable()}
              onClick={() => handleRemoveAttempt(index)}
              sx={{ ml: 1 }} // Add margin left to separate it from the cancel icon
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ));

      default:
        return (
          <Typography variant="body2">Unsupported program type</Typography>
        );
    }
  };
  if (loading) return <LoadingIndicator />;
  else
    return (
      <>
        <PopUp
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          title="Confirm Deletion"
          message="Are you sure you want to delete this?"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          onPrimaryButtonClick={handlePrimaryAction}
          onSecondaryButtonClick={handleSecondaryAction}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          {" "}
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: "100%",
              maxWidth: "None",
              margin: "auto",
              my: 4,
            }}>
            {" "}
            {/* Adjust the width and max-width as needed, margin for top and bottom */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title={
                    goal.masteryType === "Automatic" ? "Automatic" : "Manual"
                  }
                  open={tooltipOpen}
                  onClose={() => setTooltipOpen(false)}
                  onOpen={() => setTooltipOpen(true)}
                  onClick={() => setTooltipOpen(!tooltipOpen)}
                  placement="top">
                  <Chip
                    label={goal?.masteryType?.charAt(0)}
                    color="primary"
                    size="small"
                    sx={{ mr: 1, fontWeight: "bold" }}
                  />
                </Tooltip>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {goal.goalName} [{goal.programType}]
                </Typography>
              </Box>
              <IconButton
                onClick={handleSessionNoteClick}
                sx={{ color: "black", ml: 0.5 }} // Adjusts margin-left to reduce space
              >
                <NoteAltRoundedIcon />
              </IconButton>
              <IconButton
                onClick={handleGraphModalOpen}
                sx={{ color: "black", ml: 0.5 }} // Adjusts margin-left to reduce space
              >
                <ShowChartIcon />
              </IconButton>

              <Modal open={isGraphModalOpen} onClose={handleGraphModalClose}>
                <Box sx={{ ...modalStyle, width: 1000, height: 600 }}>
                  <GoalsLineGraphs
                    allGoals={goals}
                    clientId={clientId}
                    type={programtype}
                    onClose={handleGraphModalClose}
                  />
                </Box>
              </Modal>
              {operation === "update" && (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }} // Handle the delete operation
                  disabled={deleteDisable}
                  color="error">
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant="body2" sx={{ marginLeft: "4px" }}>
              {goal.goalDescription}
            </Typography>
            {shouldRender && (
              <>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{
                    padding: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Autocomplete
                    options={DATA_COLLECTION_TYPES}
                    getOptionLabel={(option) => option}
                    value={dataCollectionType}
                    autoHighlight={true}
                    onChange={(event, newValue) => {
                      setDataCollectionType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Data Collection Type"
                        variant="filled"
                        required
                        fullWidth
                        disabled={
                          CheckDataCollectionPermission(DATA_COLLECTION_TYPE) ==
                          false
                        }
                      />
                    )}
                    sx={{
                      width: "80%",
                      margin: "auto",
                      ...themeStyles.textField,
                    }} // Full width and center the Autocomplete
                    disabled={
                      CheckDataCollectionPermission(DATA_COLLECTION_TYPE) ==
                      false
                    }
                  />
                </Grid>
                <Box
                  key={
                    selectedDate + (loading ? "loading" : "loaded") + refresh
                  }>
                  {renderAttempts()}
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={handleEditDisable()}
                    startIcon={<AddBoxIcon />}
                    onClick={handleAddAttempt}
                    sx={{ ...buttonStyle }}>
                    Attempts
                  </Button>
                  <Box>
                    <Button
                      disabled={handleEditDisable()}
                      variant="contained"
                      color="primary"
                      onClick={handleDoneClick}
                      sx={{ ...buttonStyle }}>
                      Save
                    </Button>
                    {/* <Button variant="contained" color="primary" sx={{ ml: 1 }}>
                      Cancel
                    </Button> */}
                  </Box>
                </Box>
              </>
            )}
          </Paper>
        </Box>
      </>
    );
};

export default GoalItem;
