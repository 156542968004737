import {
  faFolderPlus,
  faTimes,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addFolder } from "../../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators";
import { useSnackbar } from "../../../../components/SnackBarProvider";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance from "../../../../components/AxiosInstance";
import { API_BASE_URL } from "../../../../constants";
import {
  Box,
  Autocomplete,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const SubNavRightComponent = ({ currentFolder }) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchOptions, setSearchOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderDescription, setFolderDescription] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const dispatch = useDispatch();
  const { userId, userFolders } = useSelector(
    (state) => ({
      userId: localStorage.getItem("username"),
      userFolders: state.filefolders.folders,
    }),
    shallowEqual
  );
  const handleSearchIconClick = () => {
    setShowSearchBox(true);
  };

  const extractCriteria = (node, result = []) => {
    if (node.goalBankAreaStatus === "Active") {
      result.push({
        id: node.id,
        label: node.goalBankAreaName,
        type: "Goal Bank",
      });
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => extractCriteria(child, result));
    }
    return result;
  };

  // Fetch criteria and goals on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch goal bank criteria
        const criteriaResponse = await axiosInstance.post(
          API_BASE_URL + "/api/viewgoalbankarea"
        );
        const criteriaList = extractCriteria(criteriaResponse.data?.data);

        // Fetch goals
        const goalsResponse = await axiosInstance.post(
          API_BASE_URL + "/api/viewallgoals"
        );
        const goalsList = goalsResponse.data.map((goal) => ({
          id: goal.id,
          label: goal.goalName,
          goalareaId: goal.goalAreaId,
          type: "Goal",
        }));

        // Combine both lists
        setSearchOptions([...criteriaList, ...goalsList]);
      } catch (error) {
        console.log(error);
        openSnackbar("Failed to fetch data", "error");
      }
    };

    fetchData();
  }, []);

  // Handle search option selection
  const handleSearchChange = (event, newValue) => {
    if (newValue) {
      if (newValue.type === "Goal") {
        navigate(`/manageGoal/folder/${newValue.goalareaId}`);
      } else if (newValue.type === "Goal Bank") {
        navigate(`/manageGoal/folder/${newValue.id}`);
      }
    }
    setShowSearchBox(false);
  };
  const handleFolderSubmit = (e) => {
    e.preventDefault();

    const filteredFolders =
      currentFolder === "root folder"
        ? userFolders.filter(
            (folder) =>
              folder.data.parent === "" &&
              folder.data.name === folderName.trim()
          )
        : userFolders.filter(
            (folder) =>
              folder.data.parent === currentFolder.docId &&
              folder.data.name === folderName.trim()
          );
    if (!folderName) return toast.dark("Please enter folder name!");

    if (filteredFolders.length > 0)
      return toast.dark("This is alredy present in folder");
    console.log(currentFolder);
    if (!currentFolder || currentFolder === "root folder") {
      dispatch(addFolder(folderName, folderDescription, 0, "", openSnackbar));
      setFolderName("");
      setShowModal(false);
      return;
    }

    const path =
      currentFolder.data.path +
      "/" +
      currentFolder.data.name +
      ":" +
      currentFolder.docId;

    dispatch(
      addFolder(
        folderName,
        folderDescription,
        currentFolder.docId,
        path,
        openSnackbar
      )
    );
    setFolderName("");
    setFolderDescription("");
    setShowModal(false);
    return;
  };
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header className="d-flex justify-content-center align-items-center position-relative">
          <Modal.Title style={{ flex: 1, textAlign: "center" }}>
            Create Folder
          </Modal.Title>
          <Button
            variant="white"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => setShowModal(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleFolderSubmit}>
            <Form.Group controlId="formBasicFolderName" className="my-2">
              <Form.Control
                type="text"
                placeholder="Enter folder name..."
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <Form.Control
                type="text"
                className="mt-2"
                placeholder="Enter folder Description..."
                value={folderDescription}
                onChange={(e) => setFolderDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicFolderSubmit" className="mt-5">
              <Button type="submit" className="form-control" variant="primary">
                Add Folder
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {/* Search Icon Button */}
        {!showSearchBox && (
          <IconButton onClick={handleSearchIconClick}>
            <SearchIcon fontSize="large" />
          </IconButton>
        )}
        {showSearchBox && (
          <Autocomplete
            options={searchOptions}
            onChange={handleSearchChange}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingRight: "10px", // Add space between type and scrollbar
                }}>
                <span
                  style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {option.label}
                </span>
                <span style={{ fontWeight: "bold", marginLeft: "auto" }}>
                  {option.type}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Goal/Goal Bank"
                variant="outlined"
                sx={{
                  width: "300px", // Increase the length as needed
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0px", // Remove extra space
                    "&:hover": {
                      boxShadow: "none", // Prevent size changes on hover
                    },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ marginRight: "0px", padding: "0px" }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}

        {/* Create Folder Button */}
        <Button onClick={() => setShowModal(true)} variant="outline-dark">
          <FontAwesomeIcon icon={faFolderPlus} />
          &nbsp; Create Folder
        </Button>

        {/* Add Goal Button */}
        <Button
          onClick={() =>
            navigate(`/addgoal?from=${currentFolder.docId}`, {
              state: { currentFolder: currentFolder },
            })
          }
          variant="outline-dark">
          <FontAwesomeIcon icon={faBullseye} />
          &nbsp; Add Goal
        </Button>
      </Box>
    </>
  );
};

export default SubNavRightComponent;
