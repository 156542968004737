import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, setIn } from "formik";
import { useNavigate } from "react-router-dom";
import {
  themeStyles,
  buttonStyle,
  PROGRAM_BOOK,
  CREATE,
} from "../../../constants";
import dayjs from "dayjs";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import Alert from "@mui/material/Alert";
import useSharedLink from "../../../scenes/SharedLink";
import { options } from "@fullcalendar/core/preact.js";
import { useSnackbar } from "../../../components/SnackBarProvider";
import { usePermissions } from "../../../Phase3/CheckPermission";
const AddProgramBook = () => {
  const { checkPermission, giveClients, giveStaff, checkApprover } =
    usePermissions();
  const { openSnackbar } = useSnackbar();
  const sharedLink = useSharedLink();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [facilities, setFacilities] = useState([]);
  const clients = giveClients(PROGRAM_BOOK, CREATE);
  const [programbookTemplates, setProgrambookTemplates] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isClientEnabled, setIsClientEnabled] = useState(false);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const facilityPromise = axiosInstance.post(
          `${sharedLink}/viewallfacility`,
          { withCredentials: true }
        );
        const programBookTemplatePromise = axiosInstance.post(
          `${sharedLink}/viewallprogrambooktemplates`,
          { withCredentials: true }
        );

        const [facilityResponse, programBookTemplateResponse] =
          await Promise.all([facilityPromise, programBookTemplatePromise]);
        console.log(facilityResponse);
        console.log(programBookTemplateResponse);

        const activeTemplates = programBookTemplateResponse.data.filter(
          (data) => data.status.toLowerCase() === "active"
        );

        setFacilities(facilityResponse.data);
        setProgrambookTemplates(activeTemplates);
      } catch (error) {
        console.error("Error during the combined request:", error);
      }
    }

    fetchAllData(); // Call the function to execute the combined API requests
  }, []);
  const disableFutureDates = (date) => {
    return date > new Date();
  };

  // State to track if a program book has been successfully added
  const [isProgramBookAdded, setIsProgramBookAdded] = useState(false);

  const handleFormSubmit = (values, { resetForm }) => {
    openSnackbar("Processing...", "info");
    console.log(values, "values");
    const payload = {
      facilityId: values.facilityid.id,
      clientId: values.clientid.id,
      id: values.programbooktemplateid.id,
      clientName: values.clientid.firstName + " " + values.clientid.lastName,
      facilityName: values.facilityid.facilityName,
    };
    console.log(payload);
    try {
      axiosInstance
        .post(sharedLink + "/addprogrambook", payload)
        .then((res) => {
          if (res && res.status === 200) {
            console.log("success");
            // resetForm();

            openSnackbar("Assigned Successfully", "success");
            resetForm();
            // Update state to show the new button
            setIsProgramBookAdded(true); // Set the flag to true
          }
        })
        .catch((error) => {
          console.error("Error during the request:", error);
          openSnackbar(error, "error");
        })
        .finally(() => {
          console.log("finally");
        });

      console.log("values sent");
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };
  // const handleFacilityChange = async (event, newValue) => {
  //   if (newValue) {
  //     // Enable client selection
  //     const facilityId = newValue.split("(")[1].split(")")[0];

  //     try {
  //       const response = await axios.post(`${sharedLink}/viewclient`, {
  //         facilityId: facilityId,
  //         staffId: localStorage.getItem("userId"),
  //       });
  //       console.log(response.data, "clients");
  //       const filteredClients = response.data.filter(
  //         (client) => client.status === "Active"
  //       );
  //       setClients(filteredClients);
  //       setIsClientEnabled(true);
  //     } catch (error) {
  //       console.error("Error fetching clients:", error);
  //       setClients([]); // Clear clients if fetch fails
  //     }
  //   } else {
  //     setIsClientEnabled(false); // Disable client selection if facility is not selected
  //     setClients([]);
  //   }
  // };

  return (
    <div className="staff-background">
      <Box m="20px">
        <Header title="Add Program Book" subtitle="" className="headerStyle" />
        <br />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box gap="30px">
                <Autocomplete
                  fullWidth
                  options={programbookTemplates}
                  getOptionLabel={(book) => book.programBookName}
                  name="programbooktemplateid"
                  autoHighlight
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "programbooktemplateid",
                        value: newValue,
                      },
                    });
                  }}
                  value={values.programbooktemplateid}
                  onBlur={handleBlur}
                  className="animated-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Program Book Template Name"
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Program Book Template Name",
                      }}
                    />
                  )}
                  sx={{
                    ...themeStyles.textField,
                    marginBottom: "10px",
                    gridColumn: "span 4",
                  }}
                />
                <Autocomplete
                  fullWidth
                  options={facilities}
                  name="facilityid"
                  getOptionLabel={(option) => option.facilityName}
                  autoHighlight
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "facilityid",
                        value: newValue,
                      },
                    });
                    // handleFacilityChange(event, newValue);
                  }}
                  value={values.facilityid || null}
                  onBlur={handleBlur}
                  className="animated-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility Name"
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Facility Name",
                      }}
                    />
                  )}
                  sx={{
                    ...themeStyles.textField,
                    marginBottom: "10px",
                    gridColumn: "span 4",
                  }}
                />

                <Autocomplete
                  fullWidth
                  options={clients}
                  getOptionLabel={(option) =>
                    option.firstName && option.lastName
                      ? `${option.firstName} ${option.lastName}`
                      : ""
                  }
                  name="clientid"
                  autoHighlight
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "clientid",
                        value: newValue,
                      },
                    });
                  }}
                  value={values.clientid}
                  onBlur={handleBlur}
                  // disabled={!isClientEnabled}
                  className="animated-input"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name"
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Client Name",
                      }}
                    />
                  )}
                  sx={{
                    ...themeStyles.textField,
                    marginBottom: "10px",
                    gridColumn: "span 4",
                  }}
                />

                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleFormSubmit(values, { resetForm });
                    }}
                    color="secondary"
                    variant="contained"
                    justifyContent="end"
                    style={{
                      backgroundColor: "#3A57E8",
                      color: "white",
                    }}
                    sx={{ ...buttonStyle }}>
                    Add Program Book
                  </Button>
                </Box>

                {/* Conditionally render the "Manage Program Book" button */}

                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    onClick={() => navigate("/manageProgramBook")} // Navigate to the Manage Program Book path
                    color="primary"
                    variant="contained"
                    sx={{ ...buttonStyle }}>
                    Manage Program Book
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // ... your other validations ...
  phonenumber: yup
    .string()
    .matches(/^\d{10}$/, "Invalid phone number")
    .required("Phone number is required"),
});

const initialValues = {
  facilityid: "",
  id: "",
  firstname: "",
  lastname: "",
  email: "",
  dateofbirth: null,
  streetaddress: "",
  city: "",
  state: "",
  country: "United States",
  zipcode: "",
  title: "",
  gender: "",
  role: "",
  supervisor: "",
  phonenumber: "",
  npi: "",
  clientid: null,
  licensenumber: "",
  licenseexpirationdate: null,
  password: "",
  status: "Active",
};
export default AddProgramBook;
